const createMoveActions = ({ modelId, id, path, type, sourceId, targetId, extraActions = [] }) => {

    let action = {
        type: 'LIST_BEFORE',
        payload: {
            modelId,
            id,
            path,
            args: {
                id: sourceId,
                before: targetId
            }
        }
    }

    if (type === 'LIST_AFTER') {

        action = {
            type: 'LIST_AFTER',
            payload: {
                modelId,
                id,
                path,
                args: {
                    id: sourceId,
                    after: targetId
                }
            }
        }
    }

    return {
        type: 'BATCH_ACTION',
        payload: [
            {
                type: 'LIST_REMOVE',
                payload: {
                    modelId,
                    id,
                    path,
                    args: {
                        id: sourceId
                    }
                }
            },
            action,
            ...extraActions
        ]
    }
}

const moveOptionActionFactories = {
    CarModelDetail: (state, { configurationId, type, sourceId, targetId }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carModelId = configuration.get('carModelId')

        return createMoveActions({
            modelId: 'CarModel',
            id: carModelId,
            path: ['details'],
            type,
            sourceId,
            targetId
        })
    },
    CarModelOption: (state, { configurationId, type, sourceId, targetId }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carModelId = configuration.get('carModelId')

        return createMoveActions({
            modelId: 'CarModel',
            id: carModelId,
            path: ['options'],
            type,
            sourceId,
            targetId,
            extraActions: [
                {
                    type: 'RESORT_CAR_MODEL_OPTIONS_FOR_CONFIGURATION',
                    payload: {
                        configurationId
                    }
                }
            ]
        })
    },
    LeaseTerm: (state, { configurationId, type, sourceId, targetId }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carPackageId = configuration.get('carPackageId')
        const carPackage = state.getIn(['CarPackageDatas', carPackageId])
        const priceTagId = carPackage.get('priceTag')

        return createMoveActions({
            modelId: 'PriceTag',
            id: priceTagId,
            path: ['leaseTerms'],
            type,
            sourceId,
            targetId
        })
    },
    MileagePackage: (state, { configurationId, type, sourceId, targetId }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carPackageId = configuration.get('carPackageId')
        const carPackage = state.getIn(['CarPackageDatas', carPackageId])
        const priceTagId = carPackage.get('priceTag')

        return createMoveActions({
            modelId: 'PriceTag',
            id: priceTagId,
            path: ['mileagePackages'],
            type,
            sourceId,
            targetId
        })
    },
    ContractOption: (state, { type, sourceId, targetId }) => {

        const contractOption = state.getIn(['ContractOptionDatas', sourceId])

        return createMoveActions({
            modelId: 'ContractOptionGroup',
            id: contractOption.get('group'),
            path: ['options'],
            type,
            sourceId,
            targetId
        })
    }
}

export default moveOptionActionFactories