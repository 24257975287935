import React from 'react'
import Select from '../../../select'
import ChoiceToken from '../../../choice-token/ChoiceToken'

export default class SingleSelectField extends React.Component {

    render() {

        const { title, settings, value } = this.props

        return (
            <Select
                title={title}
                value={value}
                options={settings.options}
                clearable={true}
                optionRenderer={this.optionRenderer}
                onChange={this.handleChange}
            />
        )
    }

    optionRenderer = ({ option }) => {

        return <ChoiceToken {...option} />
    }

    handleChange = ({ value }) => {

        this.props.onChange({
            id: this.props.id,
            value
        })
    }
}