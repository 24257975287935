import React from 'react'
import { css } from 'emotion'
import { connect } from 'react-redux'
import H3 from './H3'
import H4 from './H4'
import ConfigurationOptionTable from './ConfigurationOptionTable'
import Button from '@sublayer/ui/lib/button'
class CarModelDetails extends React.Component {

    state = {
        open: false
    }

    render() {

        return (
            <div>
                <H3>Uitrusting</H3>
                {this.state.open ? (
                    <div className={css`margin-bottom: 24px;`}>
                        {this.props.detailGroups.map((detailGroup, index) => {

                            return (
                                <div key={index} className={css`margin-bottom: 32px;`}>
                                    <H4>{detailGroup.group.get('name')}</H4>
                                    <ConfigurationOptionTable
                                        modelId={'CarModelDetail'}
                                        options={detailGroup.details}
                                        editor={this.props.editor}
                                        context={{
                                            template: {
                                                detailGroup: detailGroup.group.get('id')
                                            }
                                        }}
                                        onEditStart={this.props.onEditStart}
                                        onMoveOption={this.props.onMoveOption}
                                        onCreateOption={this.props.onCreateOption}
                                        onRemoveOption={this.props.onRemoveOption}
                                    />
                                </div>
                            )
                        })}
                    </div>
                ) : null}
                <div>
                    <Button size={'sm'} onClick={() => this.setState({ open: !this.state.open })}>
                        {this.state.open ? 'Verberg uitrusting' : 'Toon uitrusting'}
                    </Button>
                </div>
            </div>
        )
    }
}

export default connect((state, props) => {

    const configuration = state.getIn(['ConfigurationDatas', props.configurationId])
    const carModelId = configuration.get('carModelId')
    const carPackageId = configuration.get('carPackageId')
    const carModel = state.getIn(['CarModelDatas', carModelId])

    const details = carModel.get('details').map(id =>
        state.getIn(['CarModelDetailDatas', id])
    )

    const detailGroupData = details.reduce((result, detail) => {

        if (detail.get('packages').includes(carPackageId) === false) {
            return result
        }

        const group = detail.get('detailGroup')
        let detailGroup = result.detailGroupsDatas[group]

        if (!detailGroup) {
            detailGroup = result.detailGroupsDatas[group] = {
                id: group,
                group: state.getIn(['CarModelDetailGroupDatas', group]),
                details: []
            }
            result.detailGroups.push(group)
        }

        detailGroup.details.push({
            ...detail.toJS(),
            preselected: true
        })

        return result
    }, {
        detailGroupsDatas: {},
        detailGroups: []
    })

    const detailGroups = detailGroupData.detailGroups.map(id =>
        detailGroupData.detailGroupsDatas[id]
    )

    return {
        carModel,
        packages: carModel.get('packages').map(id =>
            state.getIn(['CarPackageDatas', id])
        ),
        detailGroups,
        carPackage: state.getIn(['CarPackageDatas', props.carPackageId]),
    }
})(CarModelDetails)