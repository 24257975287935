import React from 'react'
import { connect } from 'react-redux'
import throttle from 'lodash/throttle'
import fetchQuote from './fetchQuote'
import { UserDataForm, Context, createHooks } from '@lesautodeal/components/sales'
import api from '../api'

const hooks = createHooks({ api })

class CheckoutWidget extends React.Component {

    static contextType = Context

    requests = []

    state = {
        saving: false,
        quote: null
    }

    render() {

        const quote = this.state.quote || this.props.data.quote

        return (
            <Context.Provider value={{ hooks }}>
                <UserDataForm
                    saving={this.state.saving}
                    quote={quote}
                    onFormStateSave={this.handleFormStateSave}
                    onFormSubmit={this.handleFormSubmit}
                />
            </Context.Provider>
        )
    }

    handleFormSubmit = async () => {

        const { data } = await api.request({
            method: "post",
            url: "/graphql",
            headers: {
                'x-application-test-mode': false,
                'x-application-trigger': 'Sales',
            },
            data: {
                query: `
                      mutation confirmUserDataForQuote($id: ID!) {
                          confirmUserDataForQuote(id: $id)
                      }
                      `,
                variables: {
                    id: this.props.data.quote.id
                },
            },
        })

        if (data.data.confirmUserDataForQuote) {
            this.setState({
                quote: {
                    ...this.props.data.quote,
                    state: 'review'
                }
            })
        }
    }

    handleFormStateSave = (formState) => {

        this.setState({
            saving: true
        })

        this.handleFormStateSaveThrottled(formState)
    }

    handleFormStateSaveThrottled = throttle(async (formState) => {

        const data = formState.get("data");

        const request = api.request({
            method: "post",
            url: "/graphql",
            headers: {
                'x-application-test-mode': false,
                'x-application-trigger': 'Sales',
            },
            data: {
                query: `
                      mutation setUserDataForQuote($id: ID!, $userData: JSON!) {
                          setUserDataForQuote(id: $id, userData: $userData)
                      }
                      `,
                variables: {
                    id: this.props.data.quote.id,
                    userData: data.toJS()
                },
            },
        });

        this.requests.push(request);

        await Promise.all(this.requests);

        this.setState({
            saving: false,
        });
    }, 3000);
}

export default connect((state, props) => {
    console.log('state!!!!', state.toJS())
    return {}
})(fetchQuote(CheckoutWidget))