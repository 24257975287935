const removeOptionActionFactories = {
    CarModelDetail: (state, { id, configurationId }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carModelId = configuration.get('carModelId')

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'LIST_REMOVE',
                    payload: {
                        modelId: 'CarModel',
                        id: carModelId,
                        path: ['details'],
                        args: {
                            id
                        }
                    }
                },
                {
                    type: 'REMOVE',
                    payload: {
                        modelId: 'CarModelDetail',
                        id
                    }
                },
                {
                    type: 'CONFIGURATION_UPSERT',
                    payload: {
                        id: configurationId
                    }
                }
            ]
        }
    },
    CarModelOption: (state, { id, configurationId }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carModelId = configuration.get('carModelId')

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'LIST_REMOVE',
                    payload: {
                        modelId: 'CarModel',
                        id: carModelId,
                        path: ['options'],
                        args: {
                            id
                        }
                    }
                },
                {
                    type: 'REMOVE',
                    payload: {
                        modelId: 'CarModelOption',
                        id
                    }
                },
                {
                    type: 'CONFIGURATION_UPSERT',
                    payload: {
                        id: configurationId
                    }
                }
            ]
        }
    },
    LeaseTerm: (state, { id, configurationId }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carPackageId = configuration.get('carPackageId')
        const carPackage = state.getIn(['CarPackageDatas', carPackageId])
        const priceTagId = carPackage.get('priceTag')

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'LIST_REMOVE',
                    payload: {
                        modelId: 'PriceTag',
                        id: priceTagId,
                        path: ['leaseTerms'],
                        args: {
                            id
                        }
                    }
                },
                {
                    type: 'REMOVE',
                    payload: {
                        modelId: 'LeaseTerm',
                        id
                    }
                },
                {
                    type: 'CONFIGURATION_UPSERT',
                    payload: {
                        id: configurationId
                    }
                }
            ]
        }
    },
    MileagePackage: (state, { id, configurationId }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carPackageId = configuration.get('carPackageId')
        const carPackage = state.getIn(['CarPackageDatas', carPackageId])
        const priceTagId = carPackage.get('priceTag')

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'LIST_REMOVE',
                    payload: {
                        modelId: 'PriceTag',
                        id: priceTagId,
                        path: ['mileagePackages'],
                        args: {
                            id
                        }
                    }
                },
                {
                    type: 'REMOVE',
                    payload: {
                        modelId: 'MileagePackage',
                        id
                    }
                },
                {
                    type: 'CONFIGURATION_UPSERT',
                    payload: {
                        id: configurationId
                    }
                }
            ]
        }
    },
    ContractOption: (state, { id, configurationId }) => {

        const contractOption = state.getIn(['ContractOptionDatas', id])

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'LIST_REMOVE',
                    payload: {
                        modelId: 'ContractOptionGroup',
                        id: contractOption.get('group'),
                        path: ['options'],
                        args: {
                            id
                        }
                    }
                },
                {
                    type: 'REMOVE',
                    payload: {
                        modelId: 'ContractOption',
                        id
                    }
                },
                {
                    type: 'CONFIGURATION_UPSERT',
                    payload: {
                        id: configurationId
                    }
                }
            ]
        }
    }
}

export default removeOptionActionFactories