import React from 'react'
import { connect, Provider } from 'react-redux'
import { css } from 'emotion'
import OptionEditorPanel from './OptionEditorPanel'
import CarPackages from './CarPackages'
import CarModelColors from './CarModelColors'
import CarModelOptions from './CarModelOptions'
import CarModelDetails from './CarModelDetails'
import CarModelMileagePackages from './CarModelMileagePackages'
import CarModelLeaseTerms from './CarModelLeaseTerms'
import ContractOptions from './ContractOptions'
import { createStore } from '@lesautodeal/functions/configurator'
import H2 from './H2'
import SectionSeparator from './SectionSeparator'
import ConfiguratorHeader from './ConfiguratorHeader'
import api from '../api'
import Portal from '../adaptive-dialog/Portal'
import modelFactories from './modelFactories'
import moveOptionActionFactories from './moveOptionActionFactories'
import removeOptionActionFactories from './removeOptionActionFactories'

class ConfiguratorEditor extends React.Component {

    render() {

        return (
            <div>
                <div>
                    <H2>
                        Auto
                    </H2>
                    <div className={css`margin-bottom: 50px;`}>
                        <CarPackages
                            editor={this.props.editor}
                            configurationId={this.props.configurationId}
                            onEditStart={this.props.onEditStart}
                            onMoveOption={this.props.onMoveOption}
                            onCreateOption={this.props.onCreateOption}
                            onRemoveOption={this.props.onRemoveOption}
                        />
                    </div>
                    <div className={css`margin-bottom: 50px;`}>
                        <CarModelDetails
                            editor={this.props.editor}
                            configurationId={this.props.configurationId}
                            onEditStart={this.props.onEditStart}
                            onMoveOption={this.props.onMoveOption}
                            onCreateOption={this.props.onCreateOption}
                            onRemoveOption={this.props.onRemoveOption}
                        />
                    </div>
                    <div className={css`margin-bottom: 50px;`}>
                        <CarModelColors
                            editor={this.props.editor}
                            configurationId={this.props.configurationId}
                            onChange={({ optionId }) => {
                                this.props.onConfigure({
                                    carModelColorId: optionId
                                })
                            }}
                            onEditStart={this.props.onEditStart}
                            onMoveOption={this.props.onMoveOption}
                            onCreateOption={this.props.onCreateOption}
                            onRemoveOption={this.props.onRemoveOption}
                        />
                    </div>
                    <div className={css`margin-bottom: 50px;`}>
                        <CarModelOptions
                            editor={this.props.editor}
                            configurationId={this.props.configurationId}
                            onChange={({ optionIds }) => {
                                this.props.onConfigure({
                                    carModelOptionIds: optionIds
                                })
                            }}
                            onEditStart={this.props.onEditStart}
                            onMoveOption={this.props.onMoveOption}
                            onCreateOption={this.props.onCreateOption}
                            onRemoveOption={this.props.onRemoveOption}
                        />
                    </div>
                    {this.props.configuration.get('carPackageId') ? (
                        <React.Fragment>
                            <SectionSeparator />
                            <div id="mijn-contract">
                                <H2>Contract</H2>
                                <div className={css`margin-bottom: 50px;`}>
                                    <CarModelLeaseTerms
                                        editor={this.props.editor}
                                        configurationId={this.props.configurationId}
                                        onChange={({ optionId }) => {
                                            this.props.onConfigure({
                                                leaseTermId: optionId
                                            })
                                        }}
                                        onEditStart={this.props.onEditStart}
                                        onMoveOption={this.props.onMoveOption}
                                        onCreateOption={this.props.onCreateOption}
                                        onRemoveOption={this.props.onRemoveOption}
                                    />
                                </div>
                                <SectionSeparator />
                                <div className={css`margin-bottom: 50px;`}>
                                    <CarModelMileagePackages
                                        editor={this.props.editor}
                                        configurationId={this.props.configurationId}
                                        onChange={({ optionId }) => {
                                            this.props.onConfigure({
                                                mileagePackageId: optionId
                                            })
                                        }}
                                        onEditStart={this.props.onEditStart}
                                        onMoveOption={this.props.onMoveOption}
                                        onCreateOption={this.props.onCreateOption}
                                        onRemoveOption={this.props.onRemoveOption}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    ) : null}
                    {this.props.configuration.get('carPackageId') ? (
                        <React.Fragment>
                            <div id="extras">
                                <H2>Extra's</H2>
                                <div className={css`margin-bottom: 50px;`}>
                                    <ContractOptions
                                        editor={this.props.editor}
                                        configurationId={this.props.configurationId}
                                        onChange={({ optionIds }) => {
                                            this.props.onConfigure({
                                                contractOptionIds: optionIds
                                            })
                                        }}
                                        onEditStart={this.props.onEditStart}
                                        onMoveOption={this.props.onMoveOption}
                                        onCreateOption={this.props.onCreateOption}
                                        onRemoveOption={this.props.onRemoveOption}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        )
    }
}
class Configurator extends React.Component {

    state = {
        editor: null,
        // editor: {
        //     modelId: "CarModelOption",
        //     id: "merken/volkswagen/modellen/polo/opties/7x2/parkeersensoren-park-distance-control",
        // }
    }

    handleConfigure = options => {

        this.props.dispatch({
            type: 'CONFIGURATION_UPSERT',
            payload: {
                id: this.props.configurationId,
                ...options
            }
        })
    }

    handleEditStart = ({ modelId, id }) => {

        this.setState({
            editor: {
                modelId,
                id
            }
        })
    }

    handleClose = () => {

        this.setState({
            editor: null
        })
    }

    handleMoveOption = params => {

        const moveOptionActionFactory = moveOptionActionFactories[params.modelId]

        const action = moveOptionActionFactory(this.props.state, {
            ...params,
            configurationId: this.props.configurationId
        })

        this.props.dispatch(action)
    }

    handleCreateOption = params => {

        const modelFactory = modelFactories[params.modelId]

        const action = modelFactory(this.props.state, {
            data: params.template,
            configurationId: this.props.configurationId
        })

        this.props.dispatch(action)
    }

    handleRemoveOption = ({ modelId, id }) => {

        const confirmed = window.confirm('Weet je zeker dat je deze optie wilt verwijderen?')

        if (!confirmed) {
            return
        }

        const handleRemove = () => {

            const removeOptionActionFactory = removeOptionActionFactories[modelId]

            const action = removeOptionActionFactory(this.props.state, {
                configurationId: this.props.configurationId,
                id
            })

            this.props.dispatch(action)
        }

        if (this.state.editor && this.state.editor.modelId === modelId && this.state.editor.id === id) {

            this.setState({
                editor: null
            }, handleRemove)
        } else {
            handleRemove()
        }
    }

    render() {

        return (
            <div data-component="Configurator">
                <ConfiguratorHeader
                    record={this.props.record}
                    configurationId={this.props.configurationId}
                    saveDisabled={this.props.saveDisabled}
                    onCancelChanges={this.props.onCancelChanges}
                    onApplyChanges={this.props.onApplyChanges}
                />
                <div className="row">
                    <div
                        className="col-sm-6"
                    >
                        <h3>Configurator</h3>
                        <ConfiguratorEditor
                            {...this.props}
                            editor={this.state.editor}
                            onConfigure={this.handleConfigure}
                            onEditStart={this.handleEditStart}
                            onMoveOption={this.handleMoveOption}
                            onCreateOption={this.handleCreateOption}
                            onRemoveOption={this.handleRemoveOption}
                        />
                    </div>
                    <div
                        className="col-sm-6"
                    >
                        <h3>Configurator Summary</h3>
                        Coming soon
                    </div>
                </div>
                {this.state.editor ? (
                    <Portal>
                        <OptionEditorPanel
                            configurationId={this.props.configurationId}
                            modelId={this.state.editor.modelId}
                            recordId={this.state.editor.id}
                            onClose={this.handleClose}
                            onRemoveOption={this.handleRemoveOption}
                        />
                    </Portal>
                ) : null}
            </div>

        )
    }
}

Configurator = connect((state, props) => {

    const { configurationId } = props

    const configuration = state.getIn(['ConfigurationDatas', configurationId])
    const carModelId = configuration.get('carModelId')
    const carModel = state.getIn(['CarModelDatas', carModelId])
    const carBrand = state.getIn(['CarBrandDatas', carModel.get('brand')])

    return {
        state,
        configuration,
        carModelId,
        carModel,
        carBrand
    }
})(Configurator)

class ConfiguratorComponent extends React.Component {

    state = {
        version: 0,
        loading: false
    }

    constructor(props) {
        super(props)
        this.initStore()
    }

    componentDidUpdate(prevProps) {

        const prevHasChanges = !!prevProps.record.changes
        const nextHasChanges = !!this.props.record.changes

        if (prevHasChanges !== nextHasChanges) {
            this.initStore(true)
        }
    }

    initStore = (update) => {

        let data = this.props.record.data

        if (this.props.record.changes) {
            data = this.props.record.changes
        }

        this.store = createStore({
            ...data,
            Model: this.props.schema.Model,
            ModelDatas: this.props.schema.ModelDatas
        })

        this.store.subscribe(async () => {
            const state = this.store.getState().toJS()
            // console.log(state)

            // return

            this.setState({
                loading: true
            })

            await api.request({
                method: 'POST',
                url: '/graphql',
                data: {
                    query: `
                        mutation setChangesForQuote($id: ID!, $changes: JSON!) {
                            setChangesForQuote(id: $id, changes: $changes)
                        }
                    `,
                    variables: {
                        id: this.props.recordId,
                        changes: state
                    }
                }
            })

            this.setState({
                version: this.state.version + 1,
                loading: false
            })

            this.props.onPageRefresh()
        })

        if (update) {
            this.forceUpdate()
        }
    }

    handleCancelChanges = async () => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'POST',
            url: '/graphql',
            data: {
                query: `
                    mutation cancelChangesForQuote($id: ID!) {
                        cancelChangesForQuote(id: $id)
                    }
                `,
                variables: {
                    id: this.props.recordId
                }
            }
        })

        this.setState({
            version: this.state.version + 1,
            loading: false
        })

        this.props.onPageRefresh()
    }

    handleApplyChanges = async () => {

        this.setState({
            loading: true
        })

        await api.request({
            method: 'POST',
            url: '/graphql',
            data: {
                query: `
                    mutation applyChangesForQuote($id: ID!) {
                        applyChangesForQuote(id: $id)
                    }
                `,
                variables: {
                    id: this.props.recordId
                }
            }
        })

        this.setState({
            version: this.state.version + 1,
            loading: false
        })

        this.props.onPageRefresh()
    }

    render() {

        return (
            <Provider store={this.store}>
                <Configurator
                    configurationId={this.props.recordId}
                    record={this.props.record}
                    saveDisabled={!this.props.record.changes}
                    version={this.state.version}
                    loading={this.state.loading}
                    onCancelChanges={this.handleCancelChanges}
                    onApplyChanges={this.handleApplyChanges}
                />
            </Provider>
        )
    }
}

export default ConfiguratorComponent