import React from 'react'
import { css, keyframes } from 'emotion'

const fadeIn = keyframes`
from {
    opacity: 0;
    visibility: hidden;
}
to {
    opacity: 1;
    visibility: visible;
}
`

const Popover = ({ children, placement }) => (
    <div
        className={css`
        background-color: #fff;
        box-shadow: 0px 0px 20px 5px rgba(149, 157, 165, .2);
        font-weight:var(--font-weight-regular);
        border-radius: var(--border-radius-normal);
        overflow: hidden;
        min-width: 250px;
        max-width: 400px;
        animation-name: ${fadeIn};
        animation-duration: 400ms;
        animation-fill-mode: both;
        ${placement.slice(0, 'bottom'.length) === 'bottom' ? 'margin-top: 10px;' : ''}
        ${placement.slice(0, 'top'.length) === 'top' ? 'margin-bottom: 10px;' : ''}
        ${placement.slice(0, 'left'.length) === 'left' ? 'margin-right: 10px;' : ''}
        ${placement.slice(0, 'right'.length) === 'right' ? 'margin-left: 10px;' : ''}
        `}
    >
        {children}
    </div>
)

export default Popover