import api from './api'

const hooks = {
    "Record.onChange": async ({ modelId, recordId, changed }, { onPageRefresh }) => {
        console.log('record change!', changed)

        // setLoading(true)

        await api.request({
            method: 'PATCH',
            url: `/record/${modelId}/${recordId}`,
            data: changed
        })

        onPageRefresh()

        // setLoading(false)
    },
    "button.onClick/Quote.sendContractCreatedMessageButton": async (e, { record, setLoading, label, setLabel }) => {

        setLoading(true)
        setLabel('Aan het versturen...')

        await api.request({
            method: 'POST',
            url: '/graphql',
            data: {
                query: `
                    mutation sendContractCreatedMessageForQuote($id: ID!) {
                        sendContractCreatedMessageForQuote(id: $id)
                    }
                `,
                variables: {
                    id: record.id
                }
            }
        })

        setLoading(false)
        setLabel(label)
    },
    "button.onClick/Quote.sendQuoteProposalMessageButton": async (e, { record, setLoading, label, setLabel }) => {

        setLoading(true)
        setLabel('Aan het versturen...')

        await api.request({
            method: 'POST',
            url: '/graphql',
            data: {
                query: `
                    mutation sendQuoteProposalMessageForQuote($id: ID!) {
                        sendQuoteProposalMessageForQuote(id: $id)
                    }
                `,
                variables: {
                    id: record.id
                }
            }
        })

        setLoading(false)
        setLabel(label)
    },
    "button.onClick/Quote.sendContractCheckoutRequest": async (e, { record, setLoading, label, setLabel }) => {

        setLoading(true)
        setLabel('Aan het versturen...')

        await api.request({
            method: 'POST',
            url: '/graphql',
            data: {
                query: `
                    mutation sendContractCheckoutRequest($id: ID!) {
                        sendContractCheckoutRequest(id: $id)
                    }
                `,
                variables: {
                    id: record.id
                }
            }
        })

        setLoading(false)
        setLabel(label)
    },
    "button.onClick/Quote.sendContractSignRequest": async (e, { record, setLoading, label, setLabel }) => {

        setLoading(true)
        setLabel('Aan het versturen...')

        await api.request({
            method: 'POST',
            url: '/graphql',
            data: {
                query: `
                    mutation sendContractSignRequest($id: ID!) {
                        sendContractSignRequest(id: $id)
                    }
                `,
                variables: {
                    id: record.id
                }
            }
        })

        setLoading(false)
        setLabel(label)
    },
    "button.onClick/Quote.generateContractForQuoteButton": async (e, { record, setLoading, label, setLabel }) => {

        setLoading(true)
        setLabel('Aan het genereren...')

        await api.request({
            method: 'POST',
            url: '/graphql',
            data: {
                query: `
                    mutation generateContractForQuote($id: ID!) {
                        generateContractForQuote(id: $id)
                    }
                `,
                variables: {
                    id: record.id
                }
            }
        })

        setLoading(false)
        setLabel(label)
    },
    "HasMany.onRequest": async params => {

    },
    "HasOne.onRequest": async params => {

    },
    "Table.onRecordClick": async params => {

        alert(`Table.onRecordClick ${JSON.stringify(params)}`)
    },
    "relationship.onRecordClick": async params => {

        alert(`relationship.onRecordClick ${JSON.stringify(params)}`)
    }
}

export default hooks