import React from 'react'
import { css } from 'emotion'
import { connect } from 'react-redux'
import Button from '@sublayer/ui/lib/button'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import OptionItem from './OptionItem'
const noop = () => null


const OptionContainer = SortableContainer(({ children }) => (
    <div>
        {children}
    </div>
))

const SortableOptionItem = SortableElement(OptionItem)

const Input = props => (
    <input
        className={css`
            padding: 7px 16px;
            border-radius: 6px;
            border: 1px solid #f2f2f2;
            width: 100%;
            height: 30px;
            background-color: #fff;
            border: none;
            border-radius: 6px;
            color: #191919;
            display: block;
            font-size: 16px;
            line-height: 1.42857;
            transition: border-color .15s ease-in-out;
            width: 100%;
            box-shadow: 0 0 0 1px rgba(0,0,0,.09), 0 1px 0 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.1);
            &:focus {
                -webkit-transition-duration: 0s;
                box-shadow: inset 0 0 0 2px var(--color-primary), 0 2px 4px rgba(0,0,0,.1), 0 0 1px rgba(0,0,0,.1);
                outline: 0;
                transition-duration: 0s;
            }
        `}
        {...props}
    />
)

class ConfigurationOptionTable extends React.Component {

    static defaultProps = {
        createOptionEnabled: true,
        type: 'singleSelect',
        optionIds: []
    }

    state = {
        name: ''
    }

    handleSort = ({ oldIndex, newIndex }) => {

        const optionIds = this.props.options.map(option => option.id)
        const sourceId = optionIds[oldIndex]

        this.props.onMoveOption({
            modelId: this.props.modelId,
            type: oldIndex > newIndex ? 'LIST_BEFORE' : 'LIST_AFTER',
            sourceId,
            targetId: oldIndex > newIndex ? optionIds[newIndex] : optionIds[newIndex + 1]
        })
    }

    handleMultipleSelectItemClick = ({ e, id }) => {

        e.stopPropagation()

        let type = null

        let nextOptionIds = [...this.props.optionIds]

        if (nextOptionIds.includes(id)) {
            type = 'REMOVE'
            nextOptionIds = nextOptionIds.filter(optionId => optionId !== id)
        } else {
            type = 'ADD'
            nextOptionIds.push(id)
        }

        const subjects = {
            ADD: 'Toegevoegd',
            REMOVE: 'Verwijderd'
        }

        this.props.onChange({
            type,
            subject: subjects[type],
            optionId: id,
            optionIds: nextOptionIds
        })
    }

    handleSingleSelectItemClick = ({ e, id }) => {

        e.stopPropagation()

        if (this.props.optionIds.includes(id)) {
            return
        }

        let nextOptionIds = [...this.props.optionIds]

        const optionIds = this.props.options.map(option => option.id)

        nextOptionIds = nextOptionIds.filter(id =>
            !optionIds.includes(id)
        )

        nextOptionIds.push(id)

        this.props.onChange({
            type: 'CHANGE',
            subject: 'none',
            optionId: id,
            optionIds: nextOptionIds
        })
    }

    handleCreateOption = e => {

        e.preventDefault()

        const { name } = this.state

        if (name.length === 0) {
            return
        }

        this.setState({
            name: ''
        })

        const template = this.props.context && this.props.context.template ? this.props.context.template : {}

        this.props.onCreateOption({
            modelId: this.props.modelId,
            template: {
                name,
                ...template
            }
        })
    }

    render() {

        return (
            <div
                className={css`
                `}
            >
                <div
                    className={css`
                        margin-bottom: 16px;
                    `}
                >
                    <OptionContainer
                        onSortEnd={this.handleSort}
                        pressDelay={200}
                        lockAxis={'y'}
                        shouldCancelStart={() => this.props.options.length === 1}
                    >
                        {this.props.options.map((option, index) => {

                            const { type } = this.props

                            const optionIds = this.props.optionIds

                            const checked = optionIds.includes(option.id)

                            let statusType = null

                            if (checked) {
                                statusType = 'checked'
                            }

                            if (option.preselected) {
                                statusType = 'preselected'
                            }

                            const handleClick = type === 'singleSelect' ? this.handleSingleSelectItemClick : this.handleMultipleSelectItemClick

                            return (
                                <SortableOptionItem
                                    key={index}
                                    index={index}
                                    checked={checked}
                                    statusType={statusType}
                                    modelId={this.props.modelId}
                                    option={option}
                                    fade={this.props.editor && this.props.editor.id !== option.id}
                                    editing={this.props.editor && this.props.editor.id === option.id}
                                    onClick={option.preselected ? noop : handleClick}
                                    editorRenderer={this.props.editorRenderer}
                                    onEditStart={this.props.onEditStart}
                                    onRemove={this.props.onRemoveOption}
                                />
                            )
                        })}
                    </OptionContainer>
                </div>
                {this.props.createOptionEnabled ? (
                    <form onSubmit={this.handleCreateOption}>
                        <div
                            className={css`
                        display: flex;
                        align-items: center;
                    `}
                        >
                            <div
                                className={css`
                            flex-grow: 1;
                        `}
                            >
                                <Input
                                    value={this.state.name}
                                    onChange={e => this.setState({ name: e.target.value })}
                                    placeholder={`Vul hier de naam voor een nieuwe ${this.props.model.get('name')} in...`}
                                />
                            </div>
                            <div
                                className={css`
                            margin-left: 8px;
                            flex-shrink: 0;
                        `}
                            >
                                <Button
                                    type={'submit'}
                                    size={'sm'}
                                    primary
                                    disabled={this.state.name.length === 0}
                                >
                                    Toevoegen
                            </Button>
                            </div>
                        </div>
                    </form>
                ) : null}
            </div>
        )
    }
}

export default connect((state, props) => {

    return {
        model: state.getIn(['ModelDatas', props.modelId])
    }
})(ConfigurationOptionTable)