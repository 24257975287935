import React, { useState } from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import { css } from 'emotion'
import icons from './icons'
import Popover from './Popover'
import Portal from './Portal'

const ErrorTooltip = ({ message, placement = 'top' }) => {

    const [open, setOpen] = useState(false)

    return message ? (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div
                        ref={ref}
                        className={css`
                         cursor: pointer;
                         display: flex;
                         align-items: center;
                         color: var(--color-danger);
                     `}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                    >
                        {icons.exclamationCircle({ height: 16 })}
                    </div>
                )}
            </Reference>
            {open ? (
                <Popper placement={placement || 'bottom-start'}>
                    {({ ref, style, placement, arrowProps }) => (
                        <Portal onClose={() => setOpen(false)}>
                            <div ref={ref} style={style} data-placement={placement} className={css`z-index:1300;`}>
                                <Popover placement={placement}>
                                    <div className={css`color: var(--color-text); padding: 24px; line-height: 1.375;`}>
                                        {message}
                                    </div>
                                </Popover>
                                <div ref={arrowProps.ref} style={arrowProps.style} />
                            </div>
                        </Portal>
                    )}
                </Popper>
            ) : null}
        </Manager>
    ) : null
}

export default ErrorTooltip