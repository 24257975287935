import React from 'react'
import { connect } from 'react-redux'
import ConfigurationOptionTable from './ConfigurationOptionTable'
import H3 from './H3'
import { getCarModelOptionPriceData } from '@lesautodeal/functions/configurator'
class CarModelOptions extends React.Component {

    render() {

        return (
            <div>
                <H3
                    info={'Met onze extra opties kun je de auto compleet maken zodat je auto precies naar wens is uitgerust. Let wel op: Het toevoegen van extra’s kan invloed hebben op de levertijd.'}
                >
                    Kies extra opties
                </H3>
                <ConfigurationOptionTable
                    type={'multipleSelect'}
                    modelId={'CarModelOption'}
                    optionIds={this.props.carModelOptionIds}
                    options={this.props.options}
                    onChange={this.props.onChange}
                    editor={this.props.editor}
                    onMoveOption={this.props.onMoveOption}
                    onCreateOption={this.props.onCreateOption}
                    onRemoveOption={this.props.onRemoveOption}
                    onEditStart={this.props.onEditStart}
                />
            </div>
        )
    }
}

export default connect((state, props) => {

    const configuration = state.getIn(['ConfigurationDatas', props.configurationId])
    const { carModelId, carPackageId, leaseTermId, carModelOptionIds, configurationVersion } = configuration.toJS()
    const carModel = state.getIn(['CarModelDatas', carModelId])
    const carPackage = state.getIn(['CarPackageDatas', carPackageId])

    const options = carModel.get('options').reduce((result, id) => {

        const option = state.getIn(['CarModelOptionDatas', id])
        const { discountedPrice, price, priceTitle } = getCarModelOptionPriceData(state, { id, carPackageId, leaseTermId, configurationVersion })

        const packageData = option.get('packages').find(packageData =>
            packageData.get('package') === carPackageId
        )

        const preselected = packageData && packageData.get('state') === '●'

        if (!packageData || !['C', '●', '○'].includes(packageData.get('state'))) {
            return result
        }

        let detailDescription = null;
        let description = option.get("description");
        let contents = option.get("contents")
            ? `**Bestaande uit de opties:**\n${option.get("contents")}`
            : null;

        if (description || contents) {
            detailDescription = [description, contents].join("\n\n");
        }

        result.push({
            id: option.get('id'),
            name: option.get('name'),
            description: detailDescription,
            notes: option.get('notes'),
            orderCode: option.get('orderCode'),
            isVisibleForCustomer: option.get('isVisibleForCustomer'),
            creationType: option.get('creationType'),
            packages: option.get('packages').toJS(),
            labels: option.get('labels') ? option.get('labels').toJS() : null,
            state: packageData.get('state'),
            listPrice: packageData.get('listPrice'),
            turnover: packageData.get('turnover'),
            discountedPrice,
            price,
            priceTitle,
            preselected
        })

        return result
    }, [])

    return {
        carModel,
        carPackage,
        options,
        carModelOptionIds,
        packages: carModel.get('packages').map(id =>
            state.getIn(['CarPackageDatas', id])
        )
    }
})(CarModelOptions)