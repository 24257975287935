import React from 'react'
import ChoiceList from './../../ChoiceList'
import ChoiceToken from './../../../choice-token/ChoiceToken'
import defaultEmptyRenderer from '../../../defaultEmptyRenderer';

export default class SingleSelectField extends React.Component {

    render() {

        const { value, settings: { choices } } = this.props

        const choice = choices[value]

        if (!choice) {
            return defaultEmptyRenderer()
        }

        return (
            <ChoiceList>
                <ChoiceToken
                    {...choice}
                />
            </ChoiceList>
        )
    }
}