import React from 'react'
import { css, cx } from 'emotion'
import icons from './icons'

const Choice = ({ className, id, name, backgroundColor, color, onRemove }) => (
    <div
        className={cx(
            css`
            background-color: ${backgroundColor};
            color: ${color};
            min-width: 18px;
            line-height: 1.2;
            max-width: 100%;
            align-items: center;
            display: inline-flex;
            padding-left: 6px;
            padding-right: 6px;
            padding-top: 3px;
            padding-bottom: 3px;
            -webkit-print-color-adjust: exact;
            border-radius: 6px;
            flex: 0 0 auto;
        `, className
        )}
    >
        <div
            className={css`
                display: flex;
                align-items: center;
            `}
        >
            {name}
            {onRemove ?
                icons.close({
                    height: 12,
                    className: css`
                        cursor: pointer;
                        margin-left: 4px;
                        display: flex;
                        &:active {
                            opacity: 0.75;
                        }
                    `,
                    onClick: () => onRemove({ id })
                })
                : null
            }
        </div>
    </div>
)

export default Choice