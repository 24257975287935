import axios from 'axios'
import getSession from '@sublayer/passport-components/lib/getSession'

function getTestMode() {

    try {
        return localStorage.testMode === "true"
    } catch (e) {
        return false
    }
}

const api = axios.create({
    baseURL: window._env_.REACT_APP_API_URL
})

api.interceptors.request.use(function (config) {

    const session = getSession()

    config.headers = config.headers || {
        'x-application-trigger': 'Sales Client',
    }

    if (session) {
        config.headers['Authorization'] = `Bearer ${session}`
    }

    if (getTestMode()) {
        config.headers['x-application-test-mode'] = "true"
    }

    return config
})

export default api