import React, { useState } from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import { css } from 'emotion'
import icons from './icons'
import Popover from '../Popover'
import Portal from '../Portal'

const H3 = props => {

    const [open, setOpen] = useState(false)

    return (
        <div
            className={css`
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            `}
        >
            <h3
                className={css`
                font-size: 20px;
                font-weight: var(--font-weight-heavy);
                margin: 0;
                @media (max-width: 1050px){
                   font-size: 20px;
                }
            `}
            >
                {props.children}
            </h3>
            {props.info ? (
                <Manager>
                    <Reference>
                        {({ ref }) => (
                            <div
                                ref={ref}
                                className={css`
                                 margin-left: 16px;
                                 width: 26px;
                                 height: 26px;
                                 background-color: #fff;
                                 border-radius: 50%;
                                 display: flex;
                                 align-items: center;
                                 justify-content: center;
                                 flex-shrink: 0;
                                 cursor: pointer;
                                 color: var(--color-primary);
                                 border: 1px solid #e1e4e8; 
                                 box-shadow: 0 1px 0 rgba(27,31,35,.04);
                                 &:hover {
                                    box-shadow: 0 3px 6px rgba(149,157,165,.15);
                                }
                                &:active {
                                    box-shadow: 0 3px 6px rgba(149,157,165,.15);
                                }
                             `}
                                onMouseEnter={() => setOpen(true)}
                                onClick={() => setOpen(true)}
                                onMouseLeave={() => setOpen(false)}
                            >
                                {icons.info({ height: 12 })}
                            </div>
                        )}
                    </Reference>
                    {open ? (
                        <Popper placement={props.placement || 'bottom-start'}>
                            {({ ref, style, placement, arrowProps }) => (
                                <Portal onClose={() => setOpen(false)}>
                                    <div ref={ref} style={style} data-placement={placement} className={css`z-index:1300;`}>
                                        <Popover placement={placement}>
                                            <div className={css`color: var(--color-text); padding: 24px; line-height: 1.375;`}>
                                                {props.info}
                                            </div>
                                        </Popover>
                                        <div ref={arrowProps.ref} style={arrowProps.style} />
                                    </div>
                                </Portal>
                            )}
                        </Popper>
                    ) : null}
                </Manager>
            ) : null}
        </div>
    )
}

export default H3