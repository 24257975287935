import React from 'react'
import { connect } from 'react-redux'
import { css, cx } from 'emotion'
import InfoButton from './InfoButton'
import icons from './icons'
import { toCurrency } from '@lesautodeal/functions/utils'
import { ComparisonStatus } from './ComparisonTable'
class OptionItem extends React.Component {

    state = {
        hover: false,
        edit: false
    }

    handleMouseEnter = () => this.setState({ hover: true })
    handleMouseLeave = () => this.setState({ hover: false })

    handleEditStart = e => {

        e.stopPropagation()

        this.props.onEditStart({ modelId: this.props.modelId, id: this.props.option.id })

        // this.setState({ edit: !this.state.edit })
    }

    handleEditStop = () => this.setState({ edit: false })

    handleRemove = e => {

        e.stopPropagation()

        this.props.onRemove({ modelId: this.props.modelId, id: this.props.option.id })
    }

    render() {

        const { option, onClick, statusType, editorRenderer, hasCorrections, isSalesOnly } = this.props

        return (
            <div
                className={cx(
                    css`
                        border-radius: 6px;
                        border: 2px solid #ebebeb;
                        margin-bottom: 4px;
                        user-select: none;
                        background-color: #fff;
                    `,
                    !option.preselected ? css`
                    &:hover {
                        border-color: var(--color-primary);
                    }
                    ` : null,
                    this.props.editing ? css`
                    border-color: var(--color-primary);
                    ` : null
                )}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <div
                    className={cx(
                        css`
                        padding: 6px 12px;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        color: #737373;
                    `,
                        !option.preselected ? css`
                        cursor: pointer;
                    ` : null
                    )}
                    onClick={e => onClick({ e, id: option.id })}
                >
                    <div
                        className={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <ComparisonStatus type={statusType} muted={false} />
                    </div>
                    <div
                        className={css`
                            margin-left: 8px;
                            line-height: 1;
                            color: #000;
                        `}
                    >
                        {option.name}
                    </div>
                    {option.description ? (
                        <div
                            className={css`
                                margin-left: 8px;
                            `}
                        >
                            <InfoButton
                                iconOnly={true}
                                variant={'gray'}
                                icon={icons.info2({ height: 12 })}
                                source={option.description}
                            />
                        </div>
                    ) : null}
                    {option.notes ? (
                        <div
                            className={css`
                                margin-left: 8px;
                            `}
                        >
                            <InfoButton
                                iconOnly={true}
                                icon={icons.commentAltLines({ height: 15 })}
                                source={option.notes}
                            />
                        </div>
                    ) : null}
                    {hasCorrections ? (
                        <div
                            className={css`
                                margin-left: 8px;
                            `}
                        >
                            <InfoButton
                                iconOnly={true}
                                icon={icons.commentAltEdit({ height: 15 })}
                                variant={'danger'}
                                source={'Deze optie heeft correcties'}
                            />
                        </div>
                    ) : null}
                    {option.creationType === 'sales' ? (
                        <div
                            className={css`
                                margin-left: 8px;
                            `}
                        >
                            <InfoButton
                                iconOnly={true}
                                icon={icons.plusSquare({ height: 15 })}
                                variant={'danger'}
                                source={'Deze optie is toevoegd via Sales'}
                            />
                        </div>
                    ) : null}
                    {isSalesOnly ? (
                        <div
                            className={css`
                                margin-left: 8px;
                            `}
                        >
                            <InfoButton
                                iconOnly={true}
                                icon={icons.badgeDollar({ height: 16 })}
                                source={'Deze optie wordt alleen aangeboden vanuit Sales'}
                            />
                        </div>
                    ) : null}
                    {this.props.editing || this.state.hover ? (
                        <div
                            className={css`
                            margin-left: 16px;
                        `}
                        >
                            <div
                                className={cx(
                                    css`
                                color: var(--color-primary);
                                user-select: none;
                                cursor: pointer;
                                &:hover {
                                    text-decoration: underline;
                                }
                            `
                                )}
                                onClick={this.handleEditStart}
                            >
                                wijzigen
                            </div>
                        </div>
                    ) : null}
                    {this.props.canDelete && (this.props.editing || this.state.hover) ? (
                        <div
                            className={css`
                            margin-left: 8px;
                        `}
                        >
                            <div
                                className={cx(
                                    css`
                                color: var(--color-danger);
                                user-select: none;
                                cursor: pointer;
                                &:hover {
                                    text-decoration: underline;
                                }
                            `
                                )}
                                onClick={this.handleRemove}
                            >
                                verwijderen
                            </div>
                        </div>
                    ) : null}
                    <div
                        className={css`
                                margin-left: auto;
                                flex-shrink: 0;
                                display: flex;
                                align-items: center;
                        `}
                    >
                        {(isSalesOnly && option.isVisibleForCustomer === true) || option.isVisibleForCustomer === false ? (
                            <div
                                className={css`
                                    margin-left: 8px;
                                `}
                            >
                                <InfoButton
                                    iconOnly={true}
                                    icon={option.isVisibleForCustomer ? icons.eye({ height: 15 }) : icons.eyeSlash({ height: 15 })}
                                    source={option.isVisibleForCustomer ? 'Deze optie is zichtbaar voor de klant' : 'Deze optie is niet zichtbaar voor de klant'}
                                />
                            </div>
                        ) : null}
                           {option.discountedPrice ? (
                            <div
                                className={css`
                                        flex-shrink: 0;
                                        font-size: 12px;
                                        color: #000;
                                        margin-left: 16px;
                                        text-decoration: line-through;
                                        color: var(--color-muted);
                                   `}
                            >
                                <span>€ {toCurrency(option.discountedPrice)}</span>
                            </div>
                        ) : null}
                        {option.price === 0 || option.price ? (
                            <div
                                className={css`
                                        flex-shrink: 0;
                                        font-size: 12px;
                                        color: #000;
                                        margin-left: 16px;
                                   `}
                                title={option.priceTitle}
                            >
                                <span className={css`font-weight: var(--font-weight-bold); color: var(--color-primary);`}>+ € {toCurrency(option.price)}</span> p/mnd
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.state.edit && editorRenderer ? (
                    <div>
                        {editorRenderer({
                            record: option
                        })}
                    </div>
                ) : null}
            </div>
        )
    }
}

export default connect((state, props) => {

    const model = state.getIn(['ModelDatas', props.modelId])

    const optionCorrectionId = `${props.modelId}.${props.option.id}`
    const hasCorrections = state.get('OptionCorrection').includes(optionCorrectionId)
    const canDelete = props.option.creationType === 'sales'

    const isSalesOnly = props.option.labels.includes('show-in-website') === false

    return {
        model,
        isSalesOnly,
        canDelete,
        hasCorrections
    }
})(OptionItem)