import React from 'react'
import { css } from 'emotion'
import defaultEmptyRenderer from '../../defaultEmptyRenderer';

const replacePatternToComponent = (text, pattern, Component) => {
    const splitText = text.split(pattern);
    const matches = text.match(pattern);

    if (splitText.length <= 1) {
        return text;
    }

    return splitText.reduce((arr, element) => {
        if (!element) return arr;

        if (matches.includes(element)) {
            return [...arr, <Component value={element} />];
        }

        return [...arr, element];
    },
        []
    );
};

const URL_PATTERN = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g

const Url = ({ value }) => (
    <span
        className={css`
            text-decoration: underline;
        `}
    >
        {value}
    </span>
)

export default class SingleLineTextField extends React.Component {

    render() {

        if (!this.props.value) {
            return defaultEmptyRenderer()
        }

        return (
            <div
                data-context-id={this.props.contextId}
                data-role-id={this.props.roleId}
                className={css`
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: 100%;
                    display: inline-flex;
                    overflow: hidden;
                    align-items: center;
                `}
            >
                <div
                    className={css`
                        flex: 1 1 auto;
                        min-width: 0;
                        min-height: 0;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    `}
                >
                    {replacePatternToComponent(this.props.value, URL_PATTERN, Url)}
                </div>
            </div>
        )
    }
}