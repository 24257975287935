import React from 'react'
import { css } from 'emotion'
import { connect } from 'react-redux'
import H3 from './H3'
import ConfigurationOptionTable from './ConfigurationOptionTable'
import SectionSeparator from './SectionSeparator'
import { getContractOptionPriceData } from '@lesautodeal/functions/configurator'
class ContractOptions extends React.Component {

    render() {

        return (
            <div>
                {this.props.contractOptionGroups.map((contractOptionGroup, index) => {

                    return (
                        <React.Fragment key={index}>
                            {index !== 0 ? <SectionSeparator /> : null}
                            <div className={css`margin-bottom: 50px;`}>
                                <H3 info={contractOptionGroup.get('description')}>{contractOptionGroup.get('name')}</H3>
                                <ConfigurationOptionTable
                                    type={contractOptionGroup.get('type')}
                                    modelId={'ContractOption'}
                                    optionIds={this.props.contractOptionIds}
                                    options={contractOptionGroup.get('options').toJS()}
                                    context={{
                                        template: {
                                            group: contractOptionGroup.get('id')
                                        }
                                    }}
                                    onChange={this.props.onChange}
                                    editor={this.props.editor}
                                    onMoveOption={this.props.onMoveOption}
                                    onCreateOption={this.props.onCreateOption}
                                    onRemoveOption={this.props.onRemoveOption}
                                    onEditStart={this.props.onEditStart}
                                />
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}

export default connect((state, props) => {

    const configuration = state.getIn(['ConfigurationDatas', props.configurationId])
    const { carModelId, carPackageId, leaseTermId, contractOptionIds } = configuration.toJS()
    const carModel = state.getIn(['CarModelDatas', carModelId])
    const carPackage = state.getIn(['CarPackageDatas', carPackageId])

    const contractOptionGroups = state
        .get('ContractOptionGroup')
        .map(id =>
            state.getIn(['ContractOptionGroupDatas', id])
        )
        .map(contractOptionGroup =>
            contractOptionGroup.update('options', options =>
                options.map(id => {
                    const contractOption = state.getIn(['ContractOptionDatas', id])
                    const { price, priceTitle } = getContractOptionPriceData(state, { id, leaseTermId })
                    return contractOption.merge({ priceTitle, price })
                })
            )
        )

    return {
        contractOptionGroups,
        contractOptions: state.get('ContractOption'),
        carModel,
        carPackage,
        contractOptionIds
    }
})(ContractOptions)