import React from 'react'
import { connect } from 'react-redux'
import H3 from './H3'
import ConfigurationOptionTable from './ConfigurationOptionTable'

class CarModelLeaseTerms extends React.Component {

    render() {

        return (
            <div>
                <H3>Kies een looptijd</H3>
                <ConfigurationOptionTable
                    type={'singleSelect'}
                    modelId={'LeaseTerm'}
                    optionIds={[this.props.leaseTermId]}
                    options={this.props.options}
                    onChange={this.props.onChange}
                    editor={this.props.editor}
                    onMoveOption={this.props.onMoveOption}
                    onCreateOption={this.props.onCreateOption}
                    onRemoveOption={this.props.onRemoveOption}
                    onEditStart={this.props.onEditStart}
                />
            </div>
        )
    }
}

export default connect((state, props) => {

    const configuration = state.getIn(['ConfigurationDatas', props.configurationId])
    const { carModelId, carPackageId, leaseTermId } = configuration.toJS()
    const carModel = state.getIn(['CarModelDatas', carModelId])
    const carPackage = state.getIn(['CarPackageDatas', carPackageId])
    const priceTag = state.getIn(['PriceTagDatas', carPackage.get('priceTag')])

    const options = priceTag.get('leaseTerms').map(id => {

        const leaseTerm = state.getIn(['LeaseTermDatas', id])

        return {
            id,
            name: leaseTerm.get('name'),
            description: leaseTerm.get('description'),
            price: leaseTerm.get('price'),
            isVisibleForCustomer: leaseTerm.get('isVisibleForCustomer'),
            creationType: leaseTerm.get('creationType'),
            labels: leaseTerm.get('labels') ? leaseTerm.get('labels').toJS() : null
        }
    }).toJS()

    return {
        leaseTermId,
        carModel,
        priceTag,
        carPackage,
        options
    }
})(CarModelLeaseTerms)