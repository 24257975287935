import * as uuid from 'uuid'
const _dashify = require("dashify")
const dashify = input => input ? _dashify(`${input}`, { condense: true }) : null
const compact = require('lodash/compact')
const slug = (...items) => compact(items).join("/")

const generateIdForName = input => dashify(input) + '-' + uuid.v4().slice(0, 8) // we add a shortId to prevent id conflicts, this can be improved upon by validating the id's later.

const modelFactories = {
    CarModelDetail: (state, { configurationId, data }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carModelId = configuration.get('carModelId')
        const carPackageId = configuration.get('carPackageId')

        const { name, detailGroup } = data
        const carModelDetailId = slug(carModelId, 'uitrusting', generateIdForName(name))

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'UPSERT',
                    payload: {
                        modelId: 'CarModelDetail',
                        data: {
                            id: carModelDetailId,
                            name,
                            detailGroup,
                            optionGroups: [],
                            packages: [
                                carPackageId
                            ],
                            labels: [
                                "show-in-website",
                                "show-in-sales"
                            ],
                            creationType: 'sales'
                        }
                    }
                },
                {
                    type: 'LIST_AFTER',
                    payload: {
                        modelId: 'CarModel',
                        id: carModelId,
                        path: ['details'],
                        args: {
                            id: carModelDetailId
                        }
                    }
                }
            ]
        }
    },
    CarModelOption: (state, { configurationId, data }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carModelId = configuration.get('carModelId')
        const carPackageId = configuration.get('carPackageId')

        const { name } = data
        const carModelOptionId = slug(carModelId, 'opties', generateIdForName(name))

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'UPSERT',
                    payload: {
                        modelId: 'CarModelOption',
                        data: {
                            id: carModelOptionId,
                            name,
                            description: null,
                            notes: null,
                            packages: [
                                {
                                    package: carPackageId,
                                    state: '○',
                                    listPrice: null
                                }
                            ],
                            labels: [
                                "show-in-website",
                                "show-in-sales"
                            ],
                            creationType: 'sales',
                            isVisibleForCustomer: true
                        }
                    }
                },
                {
                    type: 'LIST_AFTER',
                    payload: {
                        modelId: 'CarModel',
                        id: carModelId,
                        path: ['options'],
                        args: {
                            id: carModelOptionId
                        }
                    }
                }
            ]
        }
    },
    LeaseTerm: (state, { configurationId, data }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carPackageId = configuration.get('carPackageId')
        const carPackage = state.getIn(['CarPackageDatas', carPackageId])
        const priceTagId = carPackage.get('priceTag')

        const { name } = data
        const leaseTermId = slug(priceTagId, 'looptijden', generateIdForName(name))

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'UPSERT',
                    payload: {
                        modelId: 'LeaseTerm',
                        data: {
                            id: leaseTermId,
                            name,
                            duration: null,
                            price: null,
                            labels: [
                                "show-in-website",
                                "show-in-sales"
                            ],
                            creationType: 'sales',
                            isVisibleForCustomer: true
                        }
                    }
                },
                {
                    type: 'LIST_AFTER',
                    payload: {
                        modelId: 'PriceTag',
                        id: priceTagId,
                        path: ['leaseTerms'],
                        args: {
                            id: leaseTermId
                        }
                    }
                }
            ]
        }
    },
    MileagePackage: (state, { configurationId, data }) => {

        const configuration = state.getIn(['ConfigurationDatas', configurationId])
        const carPackageId = configuration.get('carPackageId')
        const carPackage = state.getIn(['CarPackageDatas', carPackageId])
        const priceTagId = carPackage.get('priceTag')

        const { name } = data
        const mileagePackageId = slug(priceTagId, 'kilometerbundels', generateIdForName(name))

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'UPSERT',
                    payload: {
                        modelId: 'MileagePackage',
                        data: {
                            id: mileagePackageId,
                            name,
                            mileage: null,
                            price: null,
                            labels: [
                                "show-in-website",
                                "show-in-sales"
                            ],
                            creationType: 'sales',
                            isVisibleForCustomer: true
                        }
                    }
                },
                {
                    type: 'LIST_AFTER',
                    payload: {
                        modelId: 'PriceTag',
                        id: priceTagId,
                        path: ['mileagePackages'],
                        args: {
                            id: mileagePackageId
                        }
                    }
                }
            ]
        }
    },
    ContractOption: (state, { data }) => {

        const { name, group } = data
        const contractOptionId = slug(group, 'contract-opties', generateIdForName(name))

        return {
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'UPSERT',
                    payload: {
                        modelId: 'ContractOption',
                        data: {
                            id: contractOptionId,
                            name,
                            description: null,
                            fixedMonthPrice: null,
                            turnover: null,
                            group,
                            labels: [
                                "show-in-website",
                                "show-in-sales"
                            ],
                            creationType: 'sales',
                            isVisibleForCustomer: true
                        }
                    }
                },
                {
                    type: 'LIST_AFTER',
                    payload: {
                        modelId: 'ContractOptionGroup',
                        id: group,
                        path: ['options'],
                        args: {
                            id: contractOptionId
                        }
                    }
                }
            ]
        }
    }
}

export default modelFactories