import React from 'react'
import { css, cx } from 'emotion'
import { connect } from 'react-redux'
import Button from '@sublayer/ui/lib/button'
import spinner from '@sublayer/ui/lib/spinner'
import TotalPrice from './TotalPrice'
import { getPriceData } from '@lesautodeal/functions/configurator'

const KeyValue = ({ name, value }) => (
    <div
        className={css`
            margin-left: 16px;
            text-align: right;
        `}
    >
        <div
            className={css`
            font-size: 12px;
                                    font-weight: var(--font-weight-heavy);
                                    color: var(--color-text);
                                `}
        >
            {name}
        </div>
        <div
            className={css`
                                    font-size: 18px;
                                    color: var(--color-primary);   
                                    font-weight: var(--font-weight-bold);
                                `}
        >
            {value}
        </div>
    </div>
)

class ConfiguratorHeader extends React.Component {

    state = {
        sticky: false,
        saving: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScrollChanged)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollChanged)
    }

    handleScrollChanged = e => {

        const el = document.getElementsByTagName('html')[0]
        // console.log('scrollTop', el.scrollTop)

        const sticky = el.scrollTop > 125

        if (this.state.sticky !== sticky) {

            this.setState({
                sticky
            })
        }
    }

    handleSave = async () => {
        this.setState({
            saving: true
        })
        await this.props.onApplyChanges()
        this.setState({
            saving: false
        })
    }

    render() {

        const { record, totalPrice, carModel, carBrand, carModelColor, carPackage, onCancelChanges, saveDisabled } = this.props

        return (
            <div
                className={cx(
                    css`
                position: sticky;
                top: 70px;
                background-color: #fff;
                z-index: 1;
                margin-bottom: 16px;
                overflow: hidden;
                margin-left: -30px;
                margin-right: -30px;
            `,
                    this.state.sticky ? css`
                    border-bottom: 1px solid #ebebeb;
            ` : null
                )}
            >
                <div
                    className={css`
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    width: 100%;
                    padding: 12px 30px;
                `}
                >
                    <img
                        src={carModelColor.getIn(['image', 0, 'url'])}
                        alt={carModel.get('name')}
                        className={css`
                                height: 44px;
                            `}
                    />
                    <div
                        className={css`
                        margin-left: 12px;
                    `}
                    >
                        <div
                            className={css`
                            font-weight: var(--font-weight-bold);
                            font-size: 18px;
                        `}
                        >
                            {carBrand.get('name')} {carModel.get('name')}
                        </div>
                        <div>
                            {carPackage.get('name')}
                        </div>
                    </div>
                    <div
                        className={css`
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                    `}
                    >
                        <KeyValue
                            name={'Fiscale waarde indicatie'}
                            value={<TotalPrice value={record.totalAttentionValueEstimation} />}
                        />
                        <KeyValue
                            name={'Winst over omzet'}
                            value={`${record.profitOnTurnoverPct * 100}%`}
                        />
                        <KeyValue
                            name={'Waarborgsom'}
                            value={<TotalPrice value={record.depositAmount} />}
                        />
                        <KeyValue
                            name={'Maandprijs'}
                            value={<TotalPrice value={totalPrice} />}
                        />
                    </div>
                    <div
                        className={css`
                        margin-left: 16px;
                    `}
                    >
                        <Button onClick={onCancelChanges} disabled={saveDisabled}>
                            Annuleren
                        </Button>
                    </div>
                    <div
                        className={css`
                        margin-left: 12px;
                    `}
                    >
                        <Button icon={this.state.saving ? spinner : null} primary onClick={this.handleSave} disabled={saveDisabled}>
                            Zet live
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state, props) => {

    const { totalPrice } = getPriceData(state, { configurationId: props.configurationId })
    const configuration = state.getIn(['ConfigurationDatas', props.configurationId])
    const carModel = state.getIn(['CarModelDatas', configuration.get('carModelId')])
    const carModelColor = state.getIn(['CarModelColorDatas', configuration.get('carModelColorId')])
    const carPackage = state.getIn(['CarPackageDatas', configuration.get('carPackageId')])
    const carBrand = state.getIn(['CarBrandDatas', carModel.get('brand')])

    return {
        totalPrice,
        carModelColor,
        carModel,
        carBrand,
        carPackage
    }
})(ConfiguratorHeader)