import React from 'react'
import { css } from 'emotion'
import { connect } from 'react-redux'
import icons from './icons2'
import Content from './Content'

class CreateModelPage extends React.Component {

    render() {

        return (
            <div>
                <div
                    className={css`
                    padding-top: 70px;
          `}
                >
                    <Content>
                        {this.props.children}
                    </Content>
                </div>
                <div
                    className={css`
                    position: fixed;
                    top: 0;
                    left: 250px;
                    right: 0;
                    height: 70px;
                    border-bottom: 1px solid #ebebeb;
                    background-color: #fff;
          `}
                >
                    <div
                        className={css`
              padding: 0 30px;
              display: flex;
              align-items: center;
            `}
                    >
                        <div
                            className={css`
                font-size: 26px;
                display: flex;
                align-items: center;
                height: 70px;
              `}
                        >
                            <div>
                                <div
                                    className={css`
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                                >
                                    <div
                                        className={css`
                                            background-color: rgb(var(--primaryColor));
                                            color: #fff;
                                            width: 34px;
                                            height: 34px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 6px;
                                            margin-right: 16px;
                                        `}
                                    >
                                        {icons.list({ height: 18 })}
                                    </div>
                                    {this.props.model.get("name")} toevoegen
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state, props) => {
    return {
        model: state.getIn(["ModelDatas", props.modelId])
    }
})(CreateModelPage)
