import React from 'react'
import { css } from 'emotion'

const H4 = props => (
    <h3
        className={css`
            font-size: 16px;
            font-weight: var(--font-weight-bold);
            margin-bottom: 16px;
        `}
    >
        {props.children}
    </h3>
)

export default H4