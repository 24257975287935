import React from 'react'
import { css } from 'emotion'
import spinner from './spinner'

export default class ContractPreview extends React.Component {

    state = {
        iframe: null,
        loading: true
    }

    componentDidUpdate(prevProps) {

        if (prevProps.version !== this.props.version) {
            this.setState({
                loading: true
            })
            this.iframe.contentWindow.location.reload()
        }
    }

    render() {

        const contractDataUrl = `${window._env_.REACT_APP_API_URL}/quote-data/${this.props.recordId}`
        let previewUrl = `https://pdf-templates.lesautodeal.nl/?templateId=QuoteDocument&stateUrl=${encodeURIComponent(contractDataUrl)}`
        previewUrl = `https://pdf2.notanumber.digital/?url=${encodeURIComponent(previewUrl)}&margin=0&printBackground=true`

        return (
            <div
                className={css`
                position: sticky;
                top: 84px;
                border: 1px solid #e3e3e3;
                border-radius: var(--border-radius-normal);
                width: 100%;
                overflow: hidden;
                `}
            >
                {[this.props.version].map(version => (
                    <iframe
                        key={version}
                        ref={ref => this.iframe = ref}
                        src={previewUrl}
                        title="Configurator Preview"
                        onLoad={() => {
                            this.setState({
                                loading: false,
                            })
                        }}
                        className={css`
                            width: 100%;
                            height: calc(100vh - 100px);
                            border: none;
                        `}
                    />
                ))}
                {this.props.loading || this.state.loading ? (
                    <div
                        className={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        `}
                    >
                        <div
                            className={css`
                                text-align: center;
                            `}
                        >
                            {spinner({ height: 40 })}
                            <div
                                className={css`
                                    margin-top: 16px;
                                    font-size: 14px;
                                    font-weight: var(--font-weight-bold);
                                `}
                            >
                                Offerte preview aan het genereren
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}