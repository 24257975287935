import React from 'react'
import { css } from 'emotion'
import spinner from './spinner'

export default class ContractPreview extends React.Component {

    state = {
        iframe: null,
        loading: true,
        theme: 'default'
    }

    componentDidUpdate(prevProps) {

        if (prevProps.version !== this.props.version) {
            this.setState({
                loading: true
            })
            this.iframe.contentWindow.location.reload()
        }
    }

    render() {

        const contractDataUrl = `${window._env_.REACT_APP_API_URL}/quote-contract-data/${this.props.recordId}?theme=${this.state.theme}`
        let previewUrl = `https://pdf-templates.lesautodeal.nl/?templateId=ContractDocument&stateUrl=${encodeURIComponent(contractDataUrl)}`

        if (this.state.theme === 'default') {
            previewUrl = `https://pdf2.notanumber.digital/?url=${encodeURIComponent(previewUrl)}`
        }
        
        return (
            <div className={css`position: relative; height: 100%;`}>
                {false ? (
                    <div className={css`margin-bottom: 16px;`}>
                        <button onClick={() => this.setState({ theme: 'condensed' })} disabled={this.state.theme === 'condensed'}>condensed</button>{' '}
                        <button onClick={() => this.setState({ theme: 'default' })} disabled={this.state.theme === 'default'}>pdf</button>
                    </div>
                ) : null}
                <div
                    className={css`
                position: sticky;
                top: 84px;
                border: 1px solid #e3e3e3;
                border-radius: var(--border-radius-normal);
                width: 100%;
                `}
                >
                    {[this.props.version].map(version => (
                        <iframe
                            key={version}
                            ref={ref => this.iframe = ref}
                            src={previewUrl}
                            title="Configurator Preview"
                            onLoad={() => {
                                this.setState({
                                    loading: false,
                                })
                            }}
                            className={css`
                            width: 100%;
                            height: calc(100vh - 100px);
                            border: none;
                        `}
                        />
                    ))}
                    {this.props.loading || this.state.loading ? (
                        <div
                            className={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        `}
                        >
                            <div
                                className={css`
                                text-align: center;
                            `}
                            >
                                {spinner({ height: 40 })}
                                <div
                                    className={css`
                                    margin-top: 16px;
                                    font-size: 14px;
                                    font-weight: var(--font-weight-bold);
                                `}
                                >
                                    Contract preview aan het genereren
                            </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}