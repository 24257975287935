import React from 'react'
import { css, cx } from 'emotion'
import { toCurrency } from '@lesautodeal/functions/utils'
import InfoButton from './InfoButton'
import icons from './icons'

const check = props => (
    <svg {...props} viewBox="0 0 14 14" fill="currentColor"><polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon></svg>
)

export const defaultPackageRenderer = ({ detail, package: pkg }) => detail.packages.includes(pkg.id) ? (
    <ComparisonStatus type={'preselected'} muted={!pkg.active} />
) : null

export const ComparisonStatus = ({ type, muted }) => {
    const color = muted ? 'var(--color-muted-light)' : 'var(--color-primary)'
    return (
        <div
            className={cx(
                css`              
            border: 2px solid ${color};
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        `,
                type === 'checked' ? css`
        background-color: ${color};
        color: #fff;
        ` : css`
            color: ${color};
        `
            )}
        >
            {type === 'preselected' || type === 'checked' ? check({ height: 10 }) : null}
        </div>
    )
}

export const LegendItem = ({ icon, name }) => (
    <div className={css`display: flex; align-items: center; font-size: 14px; padding: 0 8px;`}>
        {icon({ height: 14 })}
        <div className={css`margin-left: 4px;`}>
            {name}
        </div>
    </div>
)

const ComparisonTable = ({ packages, details, onDetailClick, activeDetailHasBorder = false, packageRenderer = defaultPackageRenderer }) => {

    const secondColStyle = css`
        display: flex;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
        @media (min-width: 1200px) {
            width: 600px;
        }
    `

    return (
        <div
            className={css`
                margin-bottom: 48px;
                user-select: none;
            `}
        >
            <div
                className={css`
                `}
            >
                <div
                    className={css`
                                    margin-bottom: 16px;
                                    position: sticky;
                                    top: 140px;
                                    background-color: #fff;
                                    border: 2px solid transparent;
                                    background-clip: padding-box;
                                    @media (min-width: 1200px) {
                                        border-radius: var(--border-radius-normal);
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 0;
                                    }
                                `}
                >
                    <div
                        className={css`
                                        flex-grow: 1;
                                        align-self: stretch;
                                        background-color: #fff;
                                    `}
                    >
                    </div>
                    <div
                        className={secondColStyle}
                    >
                        {packages.map((pkg, index) => {

                            return (
                                <div
                                    key={pkg.id}
                                    className={cx(
                                        css`
                font-weight: var(--font-weight-bold);
                flex-shrink: 0;
                width: ${100 / packages.length}%;
                padding: 11px 16px;
                align-items: center;
                justify-content: center;
                text-align: center;
                border-left: 2px solid #F8F9FC;
                font-size: 14px;
                @media (max-width: 1200px) {
                    &:first-child {
                        border-left: none;
                    }
                }
                @media (max-width: 1200px) {
                    border-top: 2px solid #F8F9FC;
                    border-bottom: 2px solid #F8F9FC;
                }
            `,
                                        pkg.active ? null : css`
                color: var(--color-muted);
            `
                                    )}
                                >
                                    {pkg.abbreviation}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div>
                    {details.map(detail => {

                        return (
                            <div
                                key={detail.id}
                                onClick={e => detail.clickable && onDetailClick ? onDetailClick({ e, id: detail.id }) : null}
                                className={cx(
                                    css`
                                    background-color: #fff;
                                    border: 2px solid transparent;
                                    background-clip: padding-box;
                                    border-radius: var(--border-radius-normal);
                                    margin-bottom: 16px;
                                    @media (min-width: 1200px) {
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 2px;
                                    }
                                `,
                                    detail.clickable ? css`
                                cursor: pointer;
                                ` : null
                                )}
                            >
                                <div
                                    className={cx(
                                        css`
                                        font-weight: var(--font-weight-bold);
                                        flex-grow: 1;
                                        padding: 11px 16px;
                                        font-size: 14px;
                                        display: flex;
                                        align-items: center;
                                        @media (max-width: 1200px) {
                                            border-bottom: 2px solid #F8F9FC;
                                        }
                                    `
                                    )}
                                >
                                    <div
                                        className={css`
                                            display: flex;
                                            align-items: center;
                                            flex-grow: 1;
                                        `}
                                    >
                                        <div>{detail.name}</div>
                                        {detail.description ? (
                                            <div
                                                className={css`
                                                  margin-left: 8px;
                                              `}
                                            >
                                                <InfoButton source={detail.description} />
                                            </div>
                                        ) : null}
                                        {detail.notes ? (
                                            <div
                                                className={css`
                                                  margin-left: 8px;
                                              `}
                                            >
                                                <InfoButton
                                                    icon={icons.exclamation({ height: 7 })}
                                                    variant={'danger'}
                                                    source={detail.notes}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                    {detail.price === 0 || detail.price ? (
                                        <div
                                            className={css`
                                       flex-shrink: 0;
                                            font-size: 12px;
                                            margin-left: 8px;
                                       font-weight: 900;
                                       color: var(--color-primary);
                                   `}
                                            title={detail.priceTitle}
                                        >
                                            + € {toCurrency(detail.price)} p/mnd
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    className={secondColStyle}
                                >
                                    {packages.map((pkg, index) => {

                                        return (
                                            <div
                                                key={pkg.id}
                                                className={cx(
                                                    css`
                                                        flex-shrink: 0;
                                                        width: ${100 / packages.length}%;
                                                        padding: 11px 16px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        align-self: stretch;
                                                        border-left: 2px solid #F8F9FC;
                                                        @media (max-width: 1200px) {
                                                            &:first-child {
                                                                border-left: none;
                                                            }
                                                        }
                                                        `,
                                                    pkg.active ? css`
                                                        color: var(--color-primary);
                                                        ` : css`
                                                        color: var(--color-muted-light);
                                                        // opacity: 0.5;
                                                        `,
                                                )}
                                            >
                                                {packageRenderer({
                                                    index,
                                                    package: pkg,
                                                    detail
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ComparisonTable