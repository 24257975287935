import React from 'react'
import ReactDOM from 'react-dom'
import { css } from 'emotion'
import Button from '@sublayer/ui/lib/button'
import OptionList from '../../../select/OptionList'
import AdaptiveDialog from '../../../adaptive-dialog/AdaptiveDialog'
import Choice from '../../Choice'
import ChoiceToken from '../../../choice-token/ChoiceToken'

export default class MultipleSelectField extends React.Component {

    state = {
        open: false
    }

    componentDidMount() {

        this.button = ReactDOM.findDOMNode(this.refs.button)
    }

    optionRenderer = ({ option }) => {

        return (
            <ChoiceToken {...option} />
        )
    }

    render() {

        const choices = this.props.settings.options
            .filter(choice => {
                return (this.props.value || []).includes(choice.id) === false
            })

        return (
            <div>
                <div
                    className={css`
                        margin-bottom: 24px;
                    `}
                >
                    <div
                        className={css`
                            position: relative;
                        `}
                    >
                        <Button
                            ref={'button'}
                            size={'sm'}
                            onClick={() => this.setState({ open: true })}
                        >
                            {'Select an option'}
                        </Button>
                        {this.state.open ? (
                            <AdaptiveDialog
                                referenceElement={this.button}
                                popoverPlacement={'bottom-start'}
                                popoverWidth={300}
                                title={<div>{this.props.title}</div>}
                                onClose={() => this.setState({ open: false })}
                            >
                                {() => (
                                    <OptionList
                                        alignLeft={true}
                                        options={choices}
                                        optionRenderer={this.optionRenderer}
                                        onOptionClick={this.handleChoiceLink}
                                    />
                                )}
                            </AdaptiveDialog>
                        ) : null}
                    </div>
                </div>
                {this.props.value && this.props.value.length ? this.props.value.map(id => {

                    const choice = this.props.settings.options.find(option => option.id === id)

                    return (
                        <div
                            key={choice.id}
                            className={css`
                                max-width: 100%;
                                cursor: pointer;
                                align-items: center;
                                display: inline-flex;
                                flex: none;
                                margin-top: 4px;
                                margin-bottom: 4px;
                                margin-right: 4px;
                            `}
                        >
                            <Choice
                                {...choice}
                                onRemove={this.handleChoiceUnlink}
                            />
                        </div>

                    )
                }) : (
                        <div>
                            {'There are no options selected for this field'}
                        </div>
                    )}
            </div>
        )
    }

    handleChoiceLink = ({ id }) => {

        this.setState({ open: false })

        if (this.props.onChange) {
            this.props.onChange({
                value: [
                    ...(this.props.value || []),
                    id
                ]
            })    
        }

        if (this.props.onChoiceLink) {
            this.props.onChoiceLink({
                id: this.props.id,
                choiceId: id
            })
        }
    }

    handleChoiceUnlink = ({ id }) => {

        const nextValue = this.props.value.filter(optionId => optionId !== id)
        
        this.props.onChange({
            value: nextValue
        })

        if (this.props.onChoiceUnlink) {
            this.props.onChoiceUnlink({
                id: this.props.id,
                choiceId: id
            })
        }
    }
}