import React from 'react'
import { connect } from 'react-redux'
import H3 from './H3'
import ConfigurationOptionTable from './ConfigurationOptionTable'
import { getCarModelColorPriceData } from '@lesautodeal/functions/configurator'

class CarModelColors extends React.Component {

    render() {

        return (
            <div>
                <H3
                    info={'Afbeeldingen en kleuren kunnen afwijken van de werkelijke uitvoering.'}
                >
                    Selecteer kleur
                </H3>
                <ConfigurationOptionTable
                    type={'singleSelect'}
                    modelId={'CarModelColor'}
                    optionIds={[this.props.carModelColorId]}
                    options={this.props.options}
                    onChange={this.props.onChange}
                    editor={this.props.editor}
                    createOptionEnabled={false}
                    onMoveOption={this.props.onMoveOption}
                    onCreateOption={this.props.onCreateOption}
                    onRemoveOption={this.props.onRemoveOption}
                    onEditStart={this.props.onEditStart}
                />
            </div>
        )
    }
}

export default connect((state, props) => {

    const configuration = state.getIn(['ConfigurationDatas', props.configurationId])
    const { carModelId, carModelColorId, carPackageId } = configuration.toJS()
    const carModel = state.getIn(['CarModelDatas', carModelId])
    const carPackage = state.getIn(['CarPackageDatas', carPackageId])

    const options = carModel.get('colors')
        .map(id => {

            const carModelColor = state.getIn(['CarModelColorDatas', id])

            const { price } = getCarModelColorPriceData(state, { id, carPackageId })

            return {
                id,
                name: carModelColor.get('name'),
                price,
                labels: carModelColor.get('labels')
            }
        }).toJS()

    return {
        carModelColorId,
        carModel,
        carPackage,
        options
    }
})(CarModelColors)