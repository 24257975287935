import React from 'react'
import { css } from 'emotion'
import ReactMarkdown from 'react-markdown'

const Markdown = props => (
    <ReactMarkdown
        {...props}
        renderers={{
            list: ({ children }) => (
                <ul className={css`padding-left: 18px;`}>{children}</ul>
            )
        }}
    />
)

export default Markdown