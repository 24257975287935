import React from 'react'
import { cx, css } from 'emotion'
import { toCurrency } from '@lesautodeal/functions/utils'
import CountUp from 'react-countup'

class TotalPrice extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            start: props.value,
            end: props.value,
            active: false
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.value !== prevProps.value) {
            this.setState({
                start: prevProps.value,
                end: this.props.value,
                active: true
            })
        }
    }

    handleEnd = () => this.setState({ active: false })

    render() {

        return (
            <div className={cx(css`transition: ease 200ms opacity;`, this.state.active ? css`opacity: 0.5;` : css`opacity: 1;`)}>
                <CountUp
                    start={this.state.start}
                    end={this.state.end}
                    duration={1}
                    formattingFn={value => `€ ${toCurrency(value)}`}
                    onEnd={this.handleEnd}
                />
            </div>
        )
    }
}

export default TotalPrice