import React, { useState } from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import { css, cx } from 'emotion'
import icons from './icons'
import Popover from '../Popover'
import Portal from '../Portal'
import Markdown from './Markdown'

const InfoButton = props => {

    const [open, setOpen] = useState(false)

    console.log('source', props.source)

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div
                        ref={ref}
                        className={cx(
                            css`
                                width: 16px;
                                height: 16px;
                                background-color: #fff;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                             `,
                            props.iconOnly ? css`
                                color: var(--color-primary);
                                &:hover {
                                    color: rgba(1,145,251,0.2);
                                }
                            ` : css`
                                color: var(--color-primary);
                                border: 1px solid var(--color-primary);
                                &:hover {
                                    background-color: rgba(1,145,251,0.2);
                                }
                                &:active {
                                    background-color: var(--color-primary);
                                    color: #fff;
                                }
                             `,
                             props.variant === 'gray' ? props.iconOnly ? css`
                             color: #8792a2;
                             &:hover {
                                color: #3c4257;
                             }
                             ` : css`
                             color: #8792a2;
                             border: 1px solid #8792a2;
                             &:hover {
                                 background-color: #3c4257;
                             }
                             &:active {
                                 background-color: #3c4257;
                                 color: #fff;
                             }
                             ` : null,
                            props.variant === 'danger' ? props.iconOnly ? css`
                            color: var(--color-danger);
                            &:hover {
                               color: rgba(255, 59, 48, 0.2);
                            }
                            ` : css`
                            color: var(--color-danger);
                            border: 1px solid var(--color-danger);
                            &:hover {
                                background-color: rgba(255, 59, 48, 0.2);
                            }
                            &:active {
                                background-color: var(--color-danger);
                                color: #fff;
                            }
                            ` : null
                        )}
                        onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setOpen(true)
                        }}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                    >
                        {props.icon ? props.icon : icons.info({ height: 8 })}
                    </div>
                )}
            </Reference>
            {open ? (
                <Popper placement={props.placement || 'bottom-start'}>
                    {({ ref, style, placement, arrowProps }) => (
                        <Portal onClose={() => setOpen(false)}>
                            <div ref={ref} style={style} data-placement={placement} className={css`z-index:1300;`}>
                                <Popover placement={placement}>
                                    <div className={css`padding: 10px 24px;`}>
                                        <Markdown
                                            children={props.source}
                                            className={css`
                                                font-size: 14px;
                                                line-height: 1.571;
                                                color: var(--color-text-light);
                                            `}
                                        />
                                    </div>
                                </Popover>
                                <div ref={arrowProps.ref} style={arrowProps.style} />
                            </div>
                        </Portal>
                    )}
                </Popper>
            ) : null}
        </Manager>
    )
}

export default InfoButton