import React, { useEffect, useState } from 'react'
import api from '../api'
import PageLoader from './PageLoader'

const fetchQuote = Component => (props) => {

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        async function fetchData() {
            const response = await api.request({
                method: 'post',
                url: '/graphql',
                data: {
                    query: `
                    query quote($id: ID!) {
                        quote(id: $id) {
                        id
                        name
                        imageUrl
                        totalPrice
                        reference
                        state
                        userData
                        expiresAt
                        depositAmount
                        depositStatus
                        depositPaymentUrl
                        depositPaymentLinkId
                        signUrl
                        signStatus
                        data
                      }
                    }
                    `,
                    variables: {
                        id: props.record.id
                    }
                }
            })

            setData(response.data.data)
            setLoading(false)
        }

        fetchData()

    }, [props.record.id])

    if (loading) {
        return (
            <PageLoader />
        )
    }

    return <Component {...props} data={data} />
}

export default fetchQuote