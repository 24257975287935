import React from 'react'
import {css} from 'emotion'

export default () => (
    <div
        className={css`
            opacity: 0.7;
            padding: 8px 15px;
        `}
    >
        No options
    </div>
)