import React from 'react'
import Switch from '@sublayer/ui/lib/toggle'
import PropTypes from 'prop-types'

export default class CheckboxField extends React.Component {

    static propTypes = {
        contextId: PropTypes.oneOf(['recordDetail', 'recordGridCell', 'recordGalleryCard', 'recordListItem']),
        roleId: PropTypes.oneOf(['editor', 'readOnly']),
        value: PropTypes.bool,
        onChange: PropTypes.func
    }

    static defaultProps = {
        value: false
    }

    render() {

        const { contextId, roleId, id, value } = this.props

        const disabled = roleId === 'readOnly'
        const width = ['recordGalleryCard', 'recordListItem', 'recordGridCell'].includes(contextId) ? 24 : 38

        return (
            <Switch
                id={id}
                width={width}
                disabled={disabled}
                value={value}
                onChange={this.handleChange}
            />
        )
    }

    handleChange = ({ value }) => {
        if (this.props.onChange) {
            this.props.onChange({
                id: this.props.id,
                value: value
            })
        }
    }
}