import React from 'react'
import { connect } from 'react-redux'
import H3 from './H3'
import ConfigurationOptionTable from './ConfigurationOptionTable'
import { getCarPackagePriceData } from '@lesautodeal/functions/configurator'
class CarPackages extends React.Component {

    render() {

        return (
            <div>
                <H3>Uitvoering</H3>
                <ConfigurationOptionTable
                    type={'singleSelect'}
                    modelId={'CarPackage'}
                    optionIds={[this.props.carPackageId]}
                    options={this.props.options}
                    onChange={this.props.onChange}
                    editor={this.props.editor}
                    createOptionEnabled={false}
                    onMoveOption={this.props.onMoveOption}
                    onCreateOption={this.props.onCreateOption}
                    onRemoveOption={this.props.onRemoveOption}
                    onEditStart={this.props.onEditStart}
                />
            </div>
        )
    }
}

export default connect((state, props) => {

    const configuration = state.getIn(['ConfigurationDatas', props.configurationId])
    const { carModelId, carPackageId } = configuration.toJS()
    const carModel = state.getIn(['CarModelDatas', carModelId])
    const carPackage = state.getIn(['CarPackageDatas', carPackageId])

    const options = carModel.get('packages')
        .filter(id => id === carPackageId)
        .map(id => {

            const carPackage = state.getIn(['CarPackageDatas', id])

            const { price } = getCarPackagePriceData(state, { carPackageId: id })

            return {
                id,
                name: carPackage.get('name'),
                priceTagPriceCorrection: carPackage.get('priceTagPriceCorrection'),
                price,
                labels: carPackage.get('labels')
            }
        }).toJS()

    return {
        carPackageId,
        carModel,
        carPackage,
        options
    }
})(CarPackages)