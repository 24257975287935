import React from 'react'
import { css } from 'emotion'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import LongTextField from '../long-text-field'
import NumberField from '../number-field'
import SingleLineTextField from '../single-line-text-field'
import SingleSelectField from '../single-select-field'
import CheckboxField from '../checkbox-field'
import FieldTooltip from '@sublayer/ui/lib/field-tooltip'
import Button from '@sublayer/ui/lib/button'
import MultipleSelectField from '../multiple-select-field/contexts/recordDetail/editor'
import icons from '../icons'
// import LongTextField from './LongTextField'

const boolean = props => <div>
    <CheckboxField
        value={props.value}
        onChange={props.onChange}
        contextId={'recordDetail'}
        roleId={'editor'}
    />
</div>

const text = props => <div>
    <SingleLineTextField
        value={props.value}
        onChange={props.onChange}
        contextId={'recordDetail'}
        roleId={'editor'}
    />
</div>

const longText = props => <div>
    <LongTextField
        value={props.value}
        onChange={props.onChange}
        settings={props.field.settings}
        contextId={'recordDetail'}
        roleId={'editor'}
    />
</div>

// const longText = props => <div>
//     <LongTextField
//        value={props.value}
//        onChange={props.onChange}
//     />
// </div>

// const longText = props => <div>
//     {converter.makeHtml(props.value)}
// </div>

const relationship = props => <div>
    <SingleLineTextField
        value={props.value}
        onChange={props.onChange}
        contextId={'recordDetail'}
        roleId={'readOnly'}
    />
</div>

const singleSelect = props => <div>
    <SingleSelectField
        value={props.value}
        onChange={props.onChange}
        settings={props.field.settings}
        contextId={'recordDetail'}
        roleId={'editor'}
    />
</div>

const multipleSelect = props => <div>
    <MultipleSelectField
        value={props.value ? fromJS(props.value).toJS() : null}
        onChange={({ value }) => props.onChange({ value: fromJS(value) })}
        settings={props.field.settings}
        contextId={'recordDetail'}
        roleId={'editor'}
    />
</div>

const number = props => (
    <div>
        <NumberField
            value={props.value}
            onChange={props.onChange}
            contextId={'recordDetail'}
            roleId={'editor'}
            settings={props.field.settings}
        />
    </div>
)

const renderers = {
    multipleSelect,
    boolean,
    text,
    longText,
    number,
    relationship,
    singleSelect
}

const fieldRenderer = props => {

    const renderer = renderers[props.field.type]

    if (!renderer) {
        throw new Error(`Field renderer not found: ${props.field.type}`)
    }

    return renderer(props)
}

const writePackageField = (record, { value, fieldId }, { carPackageId }) => {
    const packageDataIndex = record.get('packages').findIndex(packageData =>
        packageData.get('package') === carPackageId
    )
    return record.setIn(['packages', packageDataIndex, fieldId], value)
}

const readPackageField = (record, { fieldId }, { carPackageId }) => {
    const packageDataIndex = record.get('packages').findIndex(packageData =>
        packageData.get('package') === carPackageId
    )
    return record.getIn(['packages', packageDataIndex, fieldId])
}

const hooks = {
    "CarModelColor.leasePrice/write": writePackageField,
    "CarModelColor.leasePrice/read": readPackageField,
    "CarModelColor.listPrice/write": writePackageField,
    "CarModelColor.listPrice/read": readPackageField,
    "CarModelOption.listPrice/write": writePackageField,
    "CarModelOption.listPrice/read": readPackageField,
    "CarModelOption.turnoverOverride/write": writePackageField,
    "CarModelOption.turnoverOverride/read": readPackageField,
    "CarModelOption.turnoverDiscountPct/write": writePackageField,
    "CarModelOption.turnoverDiscountPct/read": readPackageField,
    "CarModelOption.displayDiscountEnabled/write": writePackageField,
    "CarModelOption.displayDiscountEnabled/read": readPackageField,
    "CarModelOption.state/write": writePackageField,
    "CarModelOption.state/read": readPackageField
}

const defaultWrite = (record, { value, fieldId }) => record.set(fieldId, value)
const defaultRead = (record, { fieldId }) => record.get(fieldId)

class OptionEditorPanel extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            touched: false,
            data: props.data,
            changes: props.changes,
            formData: {
                ...props.data,
                ...props.changes
            }
        }
    }

    componentDidMount() {
        const body = document.getElementsByTagName('body')[0]
        body.classList = 'no-scroll'
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0]
        body.classList = ''
    }

    componentDidUpdate(prevProps) {

        const prev = JSON.stringify({
            modelId: prevProps.modelId,
            recordId: prevProps.recordId,
            mode: prevProps.mode,
            changes: prevProps.changes
        })

        const next = JSON.stringify({
            modelId: this.props.modelId,
            recordId: this.props.recordId,
            mode: this.props.mode,
            changes: this.props.changes
        })

        if (prev !== next) {
            this.setState({
                touched: false,
                data: this.props.data,
                changes: this.props.changes,
                formData: {
                    ...this.props.data,
                    ...this.props.changes
                }
            })
        }
    }

    handleReset = () => {

        const confirmed = window.confirm('Weet je zeker dat je deze optie wilt resetten?')

        if (!confirmed) {
            return
        }

        const { mode, modelId, recordId } = this.props

        if (mode !== 'OptionCorrection.update') {
            return
        }

        const optionCorrectionId = [modelId, recordId].join('.')

        this.props.dispatch({
            type: 'BATCH_ACTION',
            payload: [
                {
                    type: 'UPSERT',
                    payload: {
                        modelId,
                        data: this.props.dataHelper.writeDataToRecord(this.props.record, this.state.data)
                    }
                },
                {
                    type: 'REMOVE',
                    payload: {
                        modelId: 'OptionCorrection',
                        id: optionCorrectionId
                    }
                },
                {
                    type: 'CONFIGURATION_UPSERT',
                    payload: {
                        id: this.props.configurationId
                    }
                }
            ]
        })
    }

    handleSave = e => {

        e.preventDefault()

        const { mode, modelId, recordId } = this.props

        this.setState({
            touched: false
        }, () => {

            let actions = []

            // Update the actual record
            actions.push({
                type: 'UPSERT',
                payload: {
                    modelId,
                    data: this.props.dataHelper.writeDataToRecord(this.props.record, this.state.formData)
                }
            })

            if (mode === 'OptionCorrection.create' || mode === 'OptionCorrection.update') {

                const optionCorrectionId = [modelId, recordId].join('.')

                actions.push({
                    type: 'UPSERT',
                    payload: {
                        modelId: 'OptionCorrection',
                        data: {
                            id: optionCorrectionId,
                            modelId,
                            recordId,
                            type: 'overrides',
                            data: this.state.data,
                            changes: this.state.changes
                        }
                    }
                })
            }

            actions.push({
                type: 'CONFIGURATION_UPSERT',
                payload: {
                    id: this.props.configurationId
                }
            })

            this.props.dispatch({
                type: 'BATCH_ACTION',
                payload: actions
            })
        })
    }

    handleRemove = () => {

        this.props.onRemoveOption({
            modelId: this.props.modelId,
            id: this.props.recordId
        })
    }

    handleFieldChange = params => {

        const { id, value } = params

        this.setState({
            touched: true,
            formData: {
                ...this.state.formData,
                [id]: value
            },
            changes: {
                ...this.state.changes,
                [id]: value
            }
        })
    }

    render() {

        return (
            <div
                className={css`
                    position: fixed;
                    top: 70px;
                    right: 0;
                    bottom: 0;
                    width: 600px;
                    background-color: #fff;
                    border-left: 1px solid #ccc;
                    z-index: 2;
                `}
            >
                <div
                    className={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    padding: 0 16px;
                    `}
                >
                    <div>
                        <div
                            className={css`
                            font-size: 28px;
                            font-weight: bold;   
                        `}
                        >
                            {this.props.model.get('name')} bewerken
                        </div>
                    </div>
                    <div
                        className={css`
                            margin-left: auto;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        `}
                    >
                        <div
                            className={css`
                                border-radius: 6px;
                                width: 44px;
                                height: 44px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                &:hover {
                                    background-color: #f2f2f2;
                                }
                            `}
                            onClick={this.props.onClose}
                        >
                            {icons.close({ height: 14 })}
                        </div>
                    </div>
                </div>
                <div
                    className={css`
                    position: absolute;
                    top: 60px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    overflow: hidden auto;
                    `}
                >
                    <form onSubmit={this.handleSave}>
                        <div className={css`padding: 16px;`}>
                            <strong>mode:</strong> {this.props.mode}<br />
                            <strong>model:</strong> {this.props.modelId}<br />
                            <strong>id:</strong> {this.props.recordId}<br />
                        </div>
                        <div className={css`padding: 16px;`}>
                            {this.props.fields.map(field => {

                                const value = this.state.formData[field.get('id')]

                                return (
                                    <div className={css`margin-bottom: 16px;`}>
                                        <FieldTooltip field={field.toJS()}>
                                            <div className={css`display: inline-block; font-weight: bold; margin-bottom: 8px;`}>
                                                {field.get('name') ? field.get('name') : field.get('id')}
                                            </div>
                                        </FieldTooltip>
                                        {fieldRenderer({
                                            field: field.toJS(),
                                            value,
                                            onChange: ({ value }) => this.handleFieldChange({
                                                id: field.get('id'),
                                                value
                                            })
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                        <div
                            className={css`
                        padding: 16px;
                        display: flex;
                        align-items: center;
                        `}
                        >
                            <div
                                className={css`
                                flex-grow: 1;
                            `}
                            >
                                {this.props.record.get('creationType') === 'sales' ? (
                                    <Button danger onClick={this.handleRemove} disabled={this.props.record.get('creationType') !== 'sales'}>
                                        Verwijderen
                                    </Button>
                                ) : null}
                            </div>
                            <div
                                className={css`
                                flex-shrink: 0;
                            `}
                            >
                                {this.props.mode === 'OptionCorrection.create' || this.props.mode === 'OptionCorrection.update' ? (
                                    <React.Fragment>
                                        <Button onClick={this.handleReset} disabled={this.props.mode !== 'OptionCorrection.update'}>
                                            Reset
                                        </Button>{' '}
                                    </React.Fragment>
                                ) : null}
                                <Button
                                    type="submit"
                                    primary
                                    disabled={this.state.touched === false}
                                >
                                    Opslaan
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default connect((state, props) => {

    const { modelId, configurationId } = props

    const configuration = state.getIn(['ConfigurationDatas', configurationId])
    const carPackageId = configuration.get('carPackageId')

    const ctx = { carPackageId }

    const optionCorrectionId = [props.modelId, props.recordId].join('.')
    const optionCorrection = state.getIn(['OptionCorrectionDatas', optionCorrectionId])

    const model = state.getIn(['ModelDatas', props.modelId])

    const fields = model.get('fields').filter(field =>
        ['id'].includes(field.get('type')) === false
    )

    const record = state.getIn([props.modelId + 'Datas', props.recordId])

    const dataHelper = {
        readDataFromRecord: (record) => {
            return fields.reduce((result, field) => {

                const fieldId = field.get('id')

                const hookId = `${modelId}.${fieldId}/read`
                const readFn = hooks[hookId] ? hooks[hookId] : defaultRead

                const value = readFn(record, { fieldId }, ctx)

                result[fieldId] = value

                return result
            }, {})
        },
        writeDataToRecord: (record, data) => {

            fields.forEach(field => {

                const fieldId = field.get('id')

                const hookId = `${modelId}.${fieldId}/write`
                const writeFn = hooks[hookId] ? hooks[hookId] : defaultWrite

                const value = data[fieldId]
                record = writeFn(record, { value, fieldId }, ctx)
            })

            return record
        }
    }

    const creationType = record.get('creationType')

    let data = dataHelper.readDataFromRecord(record)
    let changes = {}

    let mode = 'Option.update'

    if (creationType !== 'sales') {

        mode = 'OptionCorrection.create'

        if (optionCorrection) {
            mode = 'OptionCorrection.update'
            data = optionCorrection.get('data').toObject()
            changes = optionCorrection.get('changes').toObject()
        }
    }

    console.log({
        data,
        changes,
        record: record.toJS()
    })

    return {
        mode,
        model: state.getIn(['ModelDatas', props.modelId]),
        fields,
        record,
        data,
        changes,
        dataHelper
    }
})(OptionEditorPanel)