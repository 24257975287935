import React, { useRef, useEffect } from 'react'
import { css, cx } from 'emotion'
import Button from '@sublayer/ui/lib/button'
import ErrorTooltip from './ErrorTooltip'

const Group = ({ children }) => (
    <div className={css`margin-bottom: 24px;`}>
        {children}
    </div>
)

const Label = ({ children, error }) => (
    <label
        className={cx(
            css`
            display: flex;
            align-items: center; 
            font-weight: var(--font-weight-heavy); 
            margin-bottom: 8px;
        `,
            error ? css`
            color: var(--color-danger);
        ` : null
        )}
    >
        {children} {error ? <div className={css`margin-left: 8px;`}><ErrorTooltip message={error.message} /></div> : null}
    </label>
)

const Text = React.forwardRef((props, ref) => (
    <input
        {...props}
        ref={ref}
        className={css`
        display: block;
        width: 100%;
        padding: 10px 12px;
        font-weight: var(--font-weight-regular);
        line-height: 1.5;
        background-color: #fff;
        background-clip: padding-box;
        border: 2px solid #ebebeb;
        border-radius: var(--border-radius-small);
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        &:focus {
            border-color: var(--color-text); 
            outline: none;
        }
        `}
    />
))

const Select = props => {

    return (
        <select
            className={css`
                display: block;
                width: 100%;
                padding: 10px 12px;
                font-weight: var(--font-weight-regular);
                line-height: 1.5;
                background-color: #fff;
                background-clip: padding-box;
                border: 2px solid #ebebeb;
                border-radius: var(--border-radius-small);
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                &:focus {
                    border-color: var(--color-text); 
                    outline: none;
                }
            `}
            value={JSON.stringify({ value: props.value })}
            onChange={e => {

                props.onChange({
                    value: JSON.parse(e.target.value).value
                })
            }}
        >
            <option value={JSON.stringify({ value: null })}></option>
            {props.options.map(option => {

                return (
                    <option value={JSON.stringify({ value: option.id })}>{option.name}</option>
                )
            })}
        </select>
    )
}

const Form = {
    Group,
    Label,
    Text,
    Select
}

const CreateQuoteForm = ({ data, value, onChange, errors, onSubmit }) => {

    const { carModelOptions, carPackageOptions } = data

    const textInput = useRef(null);

    useEffect(() => {
        textInput.current.focus();
    }, [])

    return (
        <form onSubmit={onSubmit}>
            {false ? (
                <Form.Group>
                    <Form.Label
                        error={errors.find(error => error.field === 'name')}
                    >
                        Naam rijschool
                        </Form.Label>
                    <Form.Text
                        value={value.name}
                        onChange={e => {

                            onChange({
                                ...value,
                                name: e.target.value
                            })
                        }}
                    />
                </Form.Group>
            ) : null}
            <Form.Group>
                <Form.Label
                    error={errors.find(error => error.field === 'email')}
                >
                    E-mail
                </Form.Label>
                <Form.Text
                    ref={textInput}
                    value={value.email}
                    onChange={e => {

                        onChange({
                            ...value,
                            email: e.target.value
                        })
                    }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label
                    error={errors.find(error => error.field === 'carModelId')}
                >
                    Model
                </Form.Label>
                <Form.Select
                    options={carModelOptions}
                    value={value.carModelId}
                    onChange={e => {

                        onChange({
                            ...value,
                            carModelId: e.value
                        })
                    }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label
                    error={errors.find(error => error.field === 'carPackageId')}
                >
                    Uitvoering
                </Form.Label>
                <Form.Select
                    options={carPackageOptions}
                    value={value.carPackageId}
                    onChange={e => {

                        onChange({
                            ...value,
                            carPackageId: e.value
                        })
                    }}
                />
            </Form.Group>
            <div
                className={css`
                display: flex;
            `}
            >
                <div
                    className={css`
                    margin-left: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                `}
                >
                    <Button
                        type="submit"
                        variant="secondary"
                        className={"orange"}
                        width="220px"
                        centered={true}
                        rounded={true}
                    >
                        Toevoegen
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default CreateQuoteForm