import React from 'react'
import { css } from 'emotion'

const SectionSeparator = props => (
    <hr
        className={css`
            border: none;
            border-bottom: 1px solid #DCE3EB;
            margin-bottom: 34px;
        `}
    />
)

export default SectionSeparator