import React from 'react'

const arrowRight = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>
)

const chevronUp = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z"></path>
    </svg>
)

const chevronDown = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
    </svg>
)

const checkmark = props => (
    <svg {...props} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M39.3455158,54.5625567 C52.9762122,42.6792348 66.6114489,30.8002225 80.2491622,18.9230058 C82.6282602,16.8511728 85.6037838,16.419496 88.2041166,17.7935354 C88.784032,18.1005933 89.3098771,18.5060535 89.7948598,18.9237241 C92.4591689,21.2182191 95.1102701,23.5252836 97.7518779,25.8395309 C100.757532,28.4719691 100.751341,31.5748704 97.7213343,34.2123365 C79.5334516,50.0443158 61.3414413,65.8727038 43.146129,81.6982186 C40.1264411,84.3249108 36.5383947,84.3198829 33.5273745,81.7007326 C23.0926107,72.624028 12.6586725,63.5458869 2.22679805,54.4663093 C-0.747074526,51.8780443 -0.74170876,48.7762204 2.23959334,46.1750266 C4.91091927,43.843541 7.58637271,41.5163651 10.2647154,39.1913439 C13.144068,36.6925028 16.7787553,36.6508434 19.6642991,39.1539941 C25.8019098,44.4781275 31.9031984,49.8342235 38.0197588,55.1766726 C38.0903393,55.2380842 38.1724768,55.2901583 38.4680066,55.5077924 C38.7821103,55.1666169 39.0314121,54.8365745 39.3455158,54.5625567 Z" fill="currentColor"></path>
        </g>
    </svg>
)

const close = props => (
    <svg {...props} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon fill="currentColor" fillRule="nonzero" points="88.0439424 0 100 11.9560576 61.9542521 50 97.762121 85.8060634 85.8060634 97.762121 50 61.9542521 11.9560576 100 0 88.0439424 38.0457479 50 2.23787896 14.1939366 14.1939366 2.23787896 50 38.0457479"></polygon>
        </g>
    </svg>
)

const filter = props => (
    <svg {...props} viewBox="0 0 16 16">
        <path fill="currentColor" fillRule="evenodd" d="M10.957 1.74c.486 0 .87.382.87.87 0 .486-.384.868-.87.868-.487 0-.87-.382-.87-.87 0-.486.383-.87.87-.87M.87 3.48h7.617c.348 1.01 1.322 1.74 2.47 1.74 1.147 0 2.086-.73 2.47-1.74h1.703c.487 0 .87-.382.87-.87 0-.486-.383-.87-.87-.87h-1.704C13.078.73 12.104 0 10.956 0 9.81 0 8.87.73 8.487 1.74H.87c-.487 0-.87.382-.87.87 0 .486.383.868.87.868M5.913 8c0 .487-.383.87-.87.87-.486 0-.87-.383-.87-.87s.384-.87.87-.87c.487 0 .87.383.87.87M16 8c0-.487-.383-.87-.87-.87H7.513c-.348-1.008-1.322-1.74-2.47-1.74-1.147 0-2.086.732-2.47 1.74H.87C.383 7.13 0 7.513 0 8s.383.87.87.87h1.704c.348 1.008 1.322 1.74 2.47 1.74 1.147 0 2.086-.732 2.47-1.74h7.616c.487 0 .87-.383.87-.87m-5.043 4.522c.486 0 .87.382.87.87 0 .486-.384.87-.87.87-.487 0-.87-.384-.87-.87 0-.488.383-.87.87-.87M.87 14.262h7.617C8.835 15.27 9.81 16 10.957 16c1.147 0 2.086-.73 2.47-1.74h1.703c.487 0 .87-.382.87-.87 0-.486-.383-.868-.87-.868h-1.704c-.348-1.01-1.322-1.74-2.47-1.74-1.147 0-2.086.73-2.47 1.74H.87c-.487 0-.87.382-.87.87 0 .486.383.87.87.87"></path>
    </svg>
)

const info = props => (
    <svg {...props} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M40.2307692,48.3516484 L34.8461538,48.3516484 C32.7219817,48.3516484 31,46.6604163 31,44.5741758 L31,44.5741758 L31,40.0412088 C31,37.9549683 32.7219817,36.2637363 34.8461538,36.2637363 L34.8461538,36.2637363 L54.8461538,36.2637363 C56.970326,36.2637363 58.6923077,37.9549683 58.6923077,40.0412088 L58.6923077,40.0412088 L58.6923077,84.6153846 L65.4551282,84.6153846 C66.5176943,84.6153846 67.5367404,85.1219079 68.2880881,86.0235251 C69.0394357,86.9251423 69.4615385,88.1479976 69.4615385,89.4230769 L69.4615385,95.1923077 C69.4615385,97.8475228 67.6678075,100 65.4551282,100 L35.0064103,100 C32.793731,100 31,97.8475228 31,95.1923077 L31,89.4230769 C31,86.7678618 32.793731,84.6153846 35.0064103,84.6153846 L40.2307692,84.6153846 L40.2307692,48.3516484 Z M49.4615385,-4.26325641e-14 C56.2588892,-4.26325641e-14 61.7692308,5.41194258 61.7692308,12.0879121 C61.7692308,18.7638816 56.2588892,24.1758242 49.4615385,24.1758242 C42.6641877,24.1758242 37.1538462,18.7638816 37.1538462,12.0879121 C37.1538462,5.41194258 42.6641877,-4.26325641e-14 49.4615385,-4.26325641e-14 Z" fill="currentColor" fillRule="nonzero"></path>
        </g>
    </svg>
)

const exchange = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 168v-16c0-13.255 10.745-24 24-24h381.97l-30.467-27.728c-9.815-9.289-10.03-24.846-.474-34.402l10.84-10.84c9.373-9.373 24.568-9.373 33.941 0l82.817 82.343c12.497 12.497 12.497 32.758 0 45.255l-82.817 82.343c-9.373 9.373-24.569 9.373-33.941 0l-10.84-10.84c-9.556-9.556-9.341-25.114.474-34.402L405.97 192H24c-13.255 0-24-10.745-24-24zm488 152H106.03l30.467-27.728c9.815-9.289 10.03-24.846.474-34.402l-10.84-10.84c-9.373-9.373-24.568-9.373-33.941 0L9.373 329.373c-12.497 12.497-12.497 32.758 0 45.255l82.817 82.343c9.373 9.373 24.569 9.373 33.941 0l10.84-10.84c9.556-9.556 9.341-25.113-.474-34.402L106.03 384H488c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"></path></svg>
)

const exclamationCircle = props => (
    <svg {...props} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z"></path></svg>
)

const exclamationTriangle = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>
)

const exclamation = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"></path></svg>
)

export default {
    exclamation,
    exclamationTriangle,
    exclamationCircle,
    arrowRight,
    chevronUp,
    chevronDown,
    checkmark,
    close,
    filter,
    info,
    exchange
}