import React from 'react'
import { css } from 'emotion'
import spinner from './spinner'

const PageLoader = props => (
    <div
        className={css`
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        `}
    >
        {spinner({ height: 34, style: { color: '#f06e00' } })}
    </div>
)

export default PageLoader