import React, { useEffect, useState } from 'react'
import { fromJS } from 'immutable'
import axios from 'axios'
import * as uuid from 'uuid'
import { css } from 'emotion'
import CreateModelPage from './CreateModelPage'
import isEmail from './isEmail'
import CreateQuoteForm from './CreateQuoteForm'
import Loader from '@sublayer/ui/lib/loader'
import { createStore } from '@lesautodeal/functions/configurator'
// import createStore from './configurator/createStore'
import api from './api'

const validationTypes = {
    min: {
        validate: (input, { value }) => input.length > value,
        message: (input, { value }) => `Vul hier minimaal ${value} tekens in.`
    },
    max: {
        validate: (input, { value }) => input.length < value,
        message: (input, { value }) => `Vul hier maximaal ${value} tekens in.`
    },
    email: {
        validate: isEmail,
        message: () => `Vul hier een geldig e-mailadres in.`
    },
    required: {
        validate: (input) => !!input,
        message: () => `Dit veld is verplicht`
    }
}

const validationRules = [
    // {
    //     field: 'name',
    //     rules: [
    //         { type: 'min', value: 1 },
    //         { type: 'max', value: 75 }
    //     ]
    // },
    {
        field: 'email',
        rules: [
            { type: 'required' },
            { type: 'email' },
            { type: 'max', value: 75 }
        ]
    },
    {
        field: 'carModelId',
        rules: [
            { type: 'required' }
        ]
    },
    {
        field: 'carPackageId',
        rules: [
            { type: 'required' }
        ]
    }
]

const CreateQuoteRoute = ({ history }) => {

    const [data, setData] = useState(null)
    const [step, setStep] = useState('fetching')

    useEffect(() => {
        async function fetchData() {
            const response = await axios.get('https://api.lesautodeal.nl/product/state.json?target=sales')
            const data = fromJS(response.data.data)

            setData(data)
            setStep('form')
        }
        fetchData()
    }, [])

    const getCarModelOptions = (state) => {
        return state
            .get('CarModel')
            .map(id =>
                state.getIn(['CarModelDatas', id])
            )
            .map(carModel => {
                const carBrand = state.getIn(['CarBrandDatas', carModel.get('brand')])
                return {
                    id: carModel.get('id'),
                    name: `${carBrand.get('name')} ${carModel.get('name')}`,
                }
            })
            .toJS()
    }

    const getCarPackageOptions = (state, { carModelId }) => {
        const carModel = state.getIn(['CarModelDatas', carModelId])
        if (!carModel) {
            return []
        }
        return carModel
            .get('packages')
            .map(id =>
                state.getIn(['CarPackageDatas', id])
            )
            .map(carPackage => ({
                id: carPackage.get('id'),
                name: carPackage.get('name'),
            }))
            .toJS()
    }

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        carModelId: null,
        carPackageId: null
    })

    const [errors, setErrors] = useState([])

    const onFormDataChange = (prev, next) => {

        if (prev.carModelId !== next.carModelId) {
            next = {
                ...next,
                carPackageId: null
            }
        }

        return next
    }

    const handleFormDataChange = value => {
        setErrors([])
        value = onFormDataChange(formData, value)
        setFormData(value)
    }

    const getErrors = () => validationRules
        .map(validationRule => {

            const input = formData[validationRule.field]

            const isRequired = !!validationRule.rules.find(rule => rule.type === 'required')

            if (!isRequired && !input) {
                return null
            }

            for (let rule of validationRule.rules) {

                const validationType = validationTypes[rule.type]

                const valid = validationType.validate(input, rule)

                if (!valid) {

                    const error = {
                        field: validationRule.field,
                        message: validationType.message(input, rule)
                    }

                    return error
                }
            }

            return null
        })
        .filter(error => !!error)

    const handleSubmit = async e => {

        e.preventDefault()

        const nextErrors = getErrors()

        setErrors(nextErrors)

        if (nextErrors.length) {
            return
        }

        try {

            setStep('sending')

            const configurationId = uuid.v4()

            const store = createStore(data)

            store.dispatch({
                type: 'CONFIGURATION_UPSERT',
                payload: {
                    id: configurationId,
                    carModelId: formData.carModelId,
                    carPackageId: formData.carPackageId
                }
            })

            const state = store.getState()
            const configuration = state.getIn(['ConfigurationDatas', configurationId])

            const response = await api.request({
                method: 'POST',
                url: '/graphql',
                data: {
                    query: `
                        mutation createConceptQuote($input: CreateQuoteInput!) {
                            createConceptQuote(input: $input)
                        }
                    `,
                    variables: {
                        input: {
                            customer: {
                                // name: formData.name,
                                email: formData.email
                            },
                            configuration: configuration
                                .remove('actions')
                                .remove('id')
                                .toJS()
                        }
                    }
                }
            })

            setStep('send')

            const quoteId = response.data.data.createConceptQuote

            history.push(`/explorer/Quote/${quoteId}`)

        } catch (e) {

            setStep('error')
        }
    }

    return (
        <CreateModelPage modelId="Quote">
            <div
                className={css`
                    max-width: 500px;
                `}
            >
                {step === 'fetching' || step === 'sending' ? (
                    <Loader />
                ) : null}
                {step === 'form' ? (
                    <CreateQuoteForm
                        data={{
                            carModelOptions: getCarModelOptions(data),
                            carPackageOptions: getCarPackageOptions(data, { carModelId: formData.carModelId })
                        }}
                        value={formData}
                        errors={errors}
                        onChange={handleFormDataChange}
                        onSubmit={handleSubmit}
                    />
                ) : null}
                {step === 'error' ? (
                    <div>
                        Er is een fout opgetreden
                    </div>
                ) : null}
                {step === 'send' ? (
                    <div>
                        De concept offerte is toevoegd
                    </div>
                ) : null}
            </div>
        </CreateModelPage>
    )
}

export default CreateQuoteRoute