import React from 'react'
import { css } from 'emotion'

const H2 = props => (
    <h2
        className={css`
            font-size: 28px;
            font-weight: var(--font-weight-heavy);
            margin: 0px 0px 12px 0px;
            color: var(--color-primary);
            @media (max-width: 1050px){
                font-size: 28px;
             }
        `}
    >
        {props.children}
    </h2>
)

export default H2